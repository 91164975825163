/* Text component (default)
***************************/

.text {
    h1,
    h2 {
        @extend %heading--primary;
    }

    h3 {
        @extend %heading--secondary;
    }

    h4 {
        @extend %heading--tertiary;
    }

    a {
        @extend %link-plain;
    }

    blockquote {
        font-size: rem(18px, 16px);
        font-style: italic;

        max-width: 75ch;
        margin-left: auto;
        margin-right: auto;
        padding: 0 em(36px, 18px);

        color: inherit;
        text-align: center;

        a {
            color: inherit;
        }
    }

    hr {
        margin: 2.5em 0;
        color: var(--color_heading_border);
    }

    p,
    li {
        letter-spacing: em(0.5px, 16px);
    }

    // Improve alignment when p-tag is the first item inside a text component
    p:first-child {
        margin-top: 0;
    }

    sub,
    sup {
        font-style: italic;
    }

    > ul,
    > ol {
        margin: 1em 0;
        padding-left: em(20px, 16px);
    }

    .text--lead {
        @extend %text--lead;
    }

    .underline {
        @extend %heading--underline;
        display: block;
    }
    
th, td, #topCell  { 
  padding: 3px 7px; 
  text-align: right; 
  font-size: 0.95rem;
  font-weight: 400;
}
th { 
  background-color: #40959d;
  color: white;
}
td {
  border-bottom: solid 0.5px #40959d;
}
#topCell {   
  background-color: #00727d;
  color: white;
}
#blankCell  {
   background-color: white;	
  color: #59667C;
   border-bottom: 0;
}
table {
   margin-bottom: 25px;
}
@media(min-width: 700px) and (max-width:1000px) {
   .cmp-text table {
      width: 100%;
    }
}
@media(min-width: 0) and (max-width:700px) {
   .cmp-text table {
      display: block;
      overflow-x: auto;
      width: 100%;
    }
}
}

