/* Teaser Horizontal component (default)
****************************************/

// Below $viewport-medium, Teaser Horizontal is (almost) identical to the regular
// Teaser component, sharing most of its styling.

.teaser-horizontal {
    @media screen and (min-width: $viewport-medium) {
        flex-direction: row;
        align-items: center;
    }

    .teaser-description {
        > p:last-child {
            margin-bottom: 0;
        }
    }

    // @note: .teaser-cta rules are in a separate block at end of this file
    // to handle the :over :focus-withing rules correctly

    @media screen and (min-width: $viewport-medium) {
        .teaser-image {
            width: 33%;
            min-height: em(185px, 16px);
            align-self: stretch;

            img {
                @supports (object-fit: cover) {
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }
        }

        .teaser-text {
            padding-top: em(36px, 16px);
            width: 66%;
        }

        .teaser-description {
            padding-right: em(60px, 16px);
        }
    }
}

.teaser.teaser-horizontal {
    .teaser-cta {
        justify-content: flex-end;

        @media screen and (min-width: $viewport-medium) {
            position: absolute;
            right: em(36px, 16px);
            bottom: em(36px, 16px);
            transition: right 400ms ease;
        }

        svg {
            width: 1.4em;
            height: 1.4em;
            padding: em(2px, 16px);
            stroke: var(--color_tertiary);
            stroke-width: 3;
            border: solid em(2px, 16px) var(--color_tertiary);
            border-radius: 50%;
        }
    }

    &:hover,
    &:focus-within {
        .teaser-cta {
            @media screen and (min-width: $viewport-medium) {
                right: em(24px, 16px);
            }
        }
    }
}
