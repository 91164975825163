/* Navigation component (FSI-brand site)
****************************************/

.navigation {
    // a:not(.btn):hover,
    // a:not(.btn):focus,
    a[aria-current] {
        color: var(--color_primary);
    }
}
