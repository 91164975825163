/* Footer component (default)
*****************************/

.footer {
    padding-top: 3em;
    padding-bottom: 2em;
    color: var(--color_footer_text);
    background-color: var(--color_footer_background);
    border-top: solid em(2px, 16px) var(--color_footer_border);

    .list {
        padding: 0;
        list-style: none;
    }

    .list,
    .text {
        a {
            color: inherit;
            font-weight: inherit;
        }
    }
}

.footer-layout {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1em;

    @media screen and (min-width: 62.25em) {
        grid-template-columns: repeat(3, 1fr);
    }
}
