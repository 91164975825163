/* SCSS placeholder templates
*****************************/

%button-nostyle {
    margin: 0;
    padding: 0;
    appearance: none;
    color: inherit;
    background-color: transparent;
    border: none;
    font: inherit;
    text-align: inherit;
    cursor: pointer;
}

%heading--primary {
    margin: 0 0 em(16px, 32px);
    font-size: rem(32px, 16px);
    font-weight: 300;
    line-height: 1.1875;
    letter-spacing: normal;
}

%heading--secondary {
    font-size: rem(24px, 16px);
    margin: 0 0 em(10px, 24px);
    font-weight: 300;
    line-height: 1.263;
    letter-spacing: normal;
}

%heading--tertiary {
    font-size: rem(19px, 16px);
    margin: 0 0 em(12px, 19px);
    font-weight: 400;
    line-height: 1.263;
    letter-spacing: normal;
}

%heading--underline {
    padding-bottom: 0.375em;
    border-bottom: solid 1px var(--color_heading_border);
}

%image-objectfit--fallback {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    max-width: none;
    height: auto;
    transform: translate(-50%, -50%);
}

%image-objectfit {
    @supports (object-fit: cover) {
        position: static;
        width: 100%;
        max-width: none;
        height: 100%;
        object-fit: cover;
        transform: none;
    }
}

%link-plain {
    color: var(--color_link);
    font-weight: 600;
    text-decoration: none;
    overflow-wrap: anywhere;

    &:hover,
    &:focus {
        text-decoration: underline;
    }
}

%site-maxwidth {
    width: 100%;
    max-width: $site-maxwidth;
    margin-left: auto;
    margin-right: auto;
    padding-left: em(24px, 16px);
    padding-right: em(24px, 16px);
}

%text-basic {
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: em(0.5px, 16px);
}

%text--lead {
    font-size: rem(22px, 16px);
    line-height: 1.363;
}
