/* CSS/Sass variables
*********************/
// Common/fallback styling settings

// COLORS
$white: #fff;
$placeholder-color: #666;

$fsi-blue: #022855;
$fsi-teal: #00727d;
$fsi-teal--bright: #0ce583;
$fsi-turquoise: #60bdb3;
$fsi-turquoise--bright: #7fe3c8;

$mufg-red: #e60000;
$mufg-red-a11y: #db0000; // Slightly darker red for accessibility requirements
$mufg-red--darker: #820000;
$mufg-gray: #5a5a5a;

$border-radius: em(10px, 16px);

// VIEWPORT BREAKPOINTS
$viewport-widest: em(1920px, 16px);
$viewport-wider: em(1440px, 16px);
$viewport-wide: em(1024px, 16px);
$viewport-medium: em(768px, 16px);
$viewport-narrow: em(620px, 16px);
$viewport-narrower: em(480px, 16px);
$viewport-narrowest: em(368px, 16px);

$site-maxwidth: em(1280px, 16px);
$navigation-switch: em(990px, 16px);
$hero-maxwidth: em(1600px, 16px);
$hero-minheight: 480px;
$hero-minheight--home: 600px;

/* CSS custom properties
************************/
// (aka CSS variables)
// https://developer.mozilla.org/en-US/docs/Web/CSS/Using_CSS_custom_properties
// :root {
//     --common_item: #{$common};
// }
