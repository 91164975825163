/* Page component (default)
***************************/

body:not(.tmpl-blank) {
    // 'Sticky' footer with CSS grid
    .sticky-footer {
        @supports (display: grid) {
            display: grid;
            grid-template-columns: 100%;
            grid-template-rows: auto 1fr auto;
            min-height: 100%;
        }
    }
}
