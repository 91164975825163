/* Footer/disclaimer component (default)
****************************************/

.footer-end {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    margin-top: em(24px, 16px);
    padding-top: em(10px, 16px);

    border-top: solid em(1px, 16px) currentColor;

    @media screen and (min-width: $viewport-narrow) {
        flex-direction: row;
        align-items: center;
    }

    a {
        color: inherit;
    }
}

.footer-disclaimer {
    grid-column: -1 / 1;
    font-size: rem(14px, 16px);
}
