/* Navigation component (default)
*********************************/

.navigation-container {
    .btn-menu {
        margin: em(22px, 16px) 0 1em 1em;
        padding: 1em;
        color: var(--color_primary);
        border: solid em(1px, 16px) var(--color_primary);
        float: right;

        @media screen and (min-width: $viewport-narrower) {
            margin-top: 1.4em;
            padding: em(24px, 16px);
        }

        @media screen and (min-width: $navigation-switch) {
            display: none;
        }
    }
}

.navigation {
    margin-left: -1.5em;
    margin-right: -1.5em;

    background-color: $white;
    clear: both;

    max-height: 1000px;
    opacity: 1;
    transition: all 0.4s ease;

    @media screen and (min-width: $navigation-switch) {
        margin: 0;
        background-color: transparent;
    }

    > ul {
        display: flex;
        flex-direction: column;
        margin: 0;
        padding: 1em 1em 1.5em 1em;

        @media screen and (min-width: $navigation-switch) {
            flex-flow: row wrap;
            justify-content: flex-end;
            align-items: center;
            padding: 1.5em 0;
        }

        > li {
            flex-grow: 0;
        }
    }

    a {
        font-weight: 400;
        letter-spacing: em(0.5px, 16px);
        text-decoration: none;
    }

    a:not(.btn) {
        position: relative;
        display: block;
        margin: 0.5em 0;
        padding: em(6px, 16px) em(3px, 16px);
        color: inherit;

        @media screen and (min-width: $navigation-switch) {
            margin-left: em(9px, 16px);
            margin-right: em(9px, 16px);
        }

        &:hover,
        &:focus {
            span::before {
                transform: translate(-50%, 0) scaleX(1);
            }
        }

        span::before {
            position: absolute;
            left: 50%;
            bottom: 0;
            width: 100%;
            height: em(3px, 16px);
            background-color: currentColor;
            transform-origin: center;
            transform: translate(-50%, 0) scaleX(0);
            transition: transform 0.2s ease-in-out;
            content: "";
        }
    }

    a.btn {
        margin: 0.5em 0;
        padding: 0.5em 1em;

        @media screen and (min-width: $navigation-switch) {
            margin: 0 0 0 0.5em;
        }
    }

    a.btn-switch {
        display: flex;
        align-items: center;
        width: max-content;

        svg {
            width: 1em;
            height: 1em;
            margin-left: 0.25em;
            transform: rotate(-45deg);
        }
    }
}

.navigation.navigation--closed {
    max-height: 0;
    opacity: 0;

    @media screen and (min-width: $navigation-switch) {
        max-height: none;
        opacity: 1;
    }
}
