/* CTA component (default)
**************************/

p.cta {
    display: flex;
    flex-wrap: wrap;
    margin: em(10px, 16px) em(-6px, 16px);

    a {
        margin: em(6px, 16px);
    }
}
