/* Teaser component (default)
*****************************/

.teaser {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    position: relative;
    margin: em(24px, 16px) 0;
    background-color: $white;
    border-radius: var(--border_radius);
    break-inside: avoid;
    overflow: hidden;

    p {
        max-width: 68ch;
    }

    &:hover,
    &:focus-within {
        box-shadow: em(1px, 16px) em(1px, 16px) em(14px, 16px) em(-2px, 16px) rgba($placeholder-color, 0.45);
    }

    .columns > & {
        margin: 0;
    }
}

.teaser-image {
    position: relative;
    min-height: em(214px, 16px);

    .teaser:not(.teaser-horizontal) & {
        border-bottom: solid em(4px, 16px) var(--color_img_border);
    }

    img {
        @extend %image-objectfit--fallback;
        @extend %image-objectfit;
        min-height: em(214px, 16px);
    }

    .flag-preview {
        font-size: rem(14px, 16px);
        position: absolute;
        top: 0;
        left: em(-48px, 14px);
        min-width: 284px;
        margin: em(48px, 14px) 0 0;
        padding: em(3px, 14px) em(48px, 14px);
        color: $white;
        background-color: var(--color_primary);

        font-weight: 400;
        letter-spacing: em(0.5px, 14px);
        text-align: center;
        text-transform: uppercase;
        transform: rotate(-34deg);
    }

    // The teaser hover/focus & focus-within states
    .teaser:hover &,
    .teaser:focus-within & {
        cursor: pointer; // For Chrome & Edge
    }
}

.teaser-link {
    color: initial;
    text-decoration: none;

    &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        content: "";
    }

    // Fallback focus style where :focus-within is not supported
    &:focus {
        text-decoration: underline;
    }

    .teaser:focus-within &:focus {
        text-decoration: none;
    }
}

.teaser-text {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;

    padding: em(24px, 16px) em(36px, 16px) em(36px, 16px);
    background-color: var(--color_white);
    z-index: 1;
}

.teaser-title {
    a {
        color: inherit;
    }
}

.teaser-description {
    background-color: var(--color_white);

    > p:first-child {
        margin-top: 0;
    }

    a {
        @extend %link-plain;
        // If there are any links within the description,
        // raise them up so they are clickable
        position: relative;
        z-index: 1;
    }
}

.teaser-cta {
    margin: auto 0 0;

    // The teaser hover/focus & focus-within states
    .teaser:hover &,
    .teaser:focus-within & {
        text-decoration: underline;
    }
}
