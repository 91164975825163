/* Footer/logos component (default)
***********************************/

.footer-logos {
    display: flex;
    margin: 0 -1em;
    padding: 0;

    flex-wrap: wrap;
    grid-column: -1 / 1;

    @media screen and (min-width: 62.25em) {
        flex-wrap: nowrap;
        grid-column: auto;
        justify-self: right;
    }

    svg {
        width: 150px;
        height: 39px;
    }

    .brand-logo {
        margin: 0.5em 1em;
    }

    .logo-fsi .logo-symbol {
        fill: $fsi-turquoise;
    }

    .logo-mufg .logo-symbol {
        fill: $mufg-red;
    }
}
