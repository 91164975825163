/* CSS/Sass variables (English/FSI-brand site)
**********************************************/

$gray: #606e7f;
$gray--light: #a3b8cc;
$gray--lighter: #eef1f4;

$gray--lead: $gray;
$heading-border: #c9d2dc;

// cf. initial brand colours in _00_variable.scss file
$brand-primary: $fsi-teal;
$brand-secondary: $fsi-blue;
$brand-tertiary: $fsi-turquoise;

// TYPEFACES
$font-family: "suisse-intl", Helvetica, Arial, sans-serif;

/* CSS custom properties
************************/
// (aka CSS variables)
// https://developer.mozilla.org/en-US/docs/Web/CSS/Using_CSS_custom_properties

:root {
    --border_radius: #{$border-radius};

    --color_white: #{$white};
    --color_gray: #{$gray};
    --color_gray--light: #{$gray--lighter};

    --color_primary: #{$fsi-teal};
    --color_secondary: #{$fsi-blue};
    --color_tertiary: #{$fsi-turquoise};

    --color_text: #{$fsi-blue};
    --color_text--lead: #{$gray--lead};
    --color_link: #{$fsi-teal};
    --color_hero--lead: #deede7;

    --color_border: #{$fsi-turquoise};
    --color_heading_border: #{$heading-border};
    --color_img_border: #{$fsi-teal};

    --color_footer_text: #{$white};
    --color_footer_background: #{$fsi-teal};
    --color_footer_border: #{$fsi-teal};

    --color_btn_background: transparent;
    --color_btn_border: currentColor;
    --color_btn_text: currentColor;

    // Make specific MediaQueryList settings available to JavaScript through
    // CSS custom properties
    --mql_navigation_switch: #{$navigation-switch};
}
