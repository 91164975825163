/* Button styles (FSI-brand site)
*********************************/

.link {
    color: var(--color_secondary);

    &:hover,
    &:focus {
        color: $fsi-teal;
    }
}

.btn--hero {
    --color_btn_background: var(--color_tertiary);
    --color_btn_border: var(--color_tertiary);
    --color_btn_text: var(--color_secondary);

    &:hover,
    &:focus {
        --color_btn_background: #{$white};
        --color_btn_border: #{$white};
    }
}

.btn--primary,
.btn--primary.outline,
.btn--secondary,
.btn--secondary.outline {
    &:hover,
    &:focus {
        --color_btn_background: var(--color_tertiary);
        --color_btn_border: var(--color_tertiary);
        --color_btn_text: var(--color_secondary);
    }
}

.btn--primary {
    --color_btn_background: var(--color_secondary);
    --color_btn_border: var(--color_secondary);
    --color_btn_text: var(--color_white);
}

.btn--primary.outline {
    --color_btn_background: transparent;
    --color_btn_border: var(--color_secondary);
    --color_btn_text: var(--color_secondary);
}

.btn--secondary {
    --color_btn_background: var(--color_primary);
    --color_btn_border: var(--color_primary);
    --color_btn_text: var(--color_white);
}

.btn--secondary.outline {
    --color_btn_background: transparent;
    --color_btn_border: var(--color_tertiary);
    --color_btn_text: var(--color_secondary);
}
