/* List component (default)
***************************/

.list {
    margin: 1em 0;
    padding: 0 1em;

    a {
        @extend %link-plain;
    }
}
