/* Card component (default)
***************************/

.card {
    --color_scheme_background: var(--color_white);
    --color_scheme_text: var(--color_text);

    display: flex;
    flex-direction: column;

    break-inside: avoid;
    border-radius: var(--border_radius);
    overflow: hidden;
}

.card-inset {
    flex: 1 0 auto; // Using long-form of `flex` to prevent IE11 from collapsing the cards, obscuring content
    padding: em(36px, 16px);

    color: var(--color_scheme_text);
    background-color: var(--color_scheme_background);
}

.card-inset,
.card-text {
    display: flex;
    flex-direction: column;
}

.card-text,
.card-description {
    flex-grow: 1;
}

.card-icon {
    flex-shrink: 0;
    margin-bottom: em(12px, 16px);
}

.card-description {
    @extend %text-basic;

    *:first-child {
        margin-top: 0;
    }

    a {
        @extend %link-plain;
    }
}

.card-cta {
    margin: 0;
}

.card-inset.card--horizontal {
    @media screen and (min-width: $viewport-narrower) {
        flex-direction: row;
    }

    .card-icon {
        margin-right: em(24px, 16px);
    }
}

.scheme--primary,
.scheme--secondary {
    // IE11 fallbacks
    .card-image + .card-text {
        color: $white;

        @supports (color: var(--color_scheme_text)) {
            color: var(--color_scheme_text);
        }
    }
}
