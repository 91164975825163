/* Placeholder styles (FSI-brand site)
**************************************/

%heading--primary,
.heading--primary {
    @extend %heading--primary;
    color: var(--color_primary);
}

%heading--secondary,
.heading--secondary {
    @extend %heading--secondary;
    color: var(--color_primary);
}

%heading--tertiary,
.heading--tertiary {
    @extend %heading--tertiary;
    color: var(--color_primary);
}
