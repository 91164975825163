/* Hero component (default)
***************************/

.hero-container {
    position: relative;

    // Counter site-maxwidth padding
    margin-left: -1.5em;
    margin-right: -1.5em;

    // Adjust left/right margins to fill screen width;
    // breaking-out of main width restriction
    @media screen and (min-width: $site-maxwidth) {
        margin-left: calc(-50vw + ((#{$site-maxwidth} / 2) - 1em));
        margin-right: calc(-50vw + ((#{$site-maxwidth} / 2) - 1em));
    }

    &.landmark {
        margin-top: 0;
    }
}

.hero {
    display: flex;
    flex-direction: column;

    position: relative;
    max-width: $hero-maxwidth;
    margin: 0 auto;

    color: var(--color_white);
    background-color: var(--color_primary);
    overflow: hidden;

    @media screen and (min-width: $viewport-medium) {
        min-height: $hero-minheight;
    }

    @media screen and (min-width: $viewport-wide) {
        flex-direction: row;
        border-radius: var(--border_radius);
    }
}

.hero-image {
    position: static;
    top: 0;
    left: 0;
    width: 100%;
    height: 280px;

    @media screen and (min-width: $viewport-wide) {
        position: absolute;
        height: 100%;
    }

    img {
        @extend %image-objectfit--fallback;
        @extend %image-objectfit;
    }
}

.hero-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    flex-grow: 1;

    position: relative;
    width: 100%;
    min-height: 30vh;
    padding: 2em;
    background-color: var(--color_primary);

    @media screen and (min-width: $viewport-wide) {
        flex-grow: 0;
        width: 50%;
        min-height: $hero-minheight; // Help IE11
        padding-left: 0;
        padding-right: 0;

        // Home page template version is deeper
        .tmpl-home & {
            min-height: $hero-minheight--home; // Help IE11
        }
    }

    h1 {
        margin-bottom: em(20px, 38px);
        font-size: em(38px, 16px);
        font-weight: 300;
        line-height: 1.1579;
    }

    .constrain-width {
        @media screen and (min-width: $viewport-wide) {
            padding-left: 15%;
            padding-right: 15%;
        }
    }
}

.hero-description {
    color: var(--color_hero--lead);
    font-size: rem(20px, 16px);
    line-height: 1.3;

    p:first-child {
        margin-top: 0;
    }

    a {
        color: inherit;
    }
}

.hero-cta {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    color: var(--color_white);

    .hero-link {
        @extend %link-plain;
        display: flex;
        margin: 0.5em 1em 0.5em 0;
        color: inherit;
        font-weight: 400;
    }

    .btn + .hero-link {
        margin-left: 1em;
    }

    svg {
        margin-right: em(6px, 16px);
        stroke: inherit;
    }
}
