/* Global styles
****************/

html,
body {
    height: 100%;
    color: var(--color_text);
    font-family: $font-family;
    font-size: 1rem;
    // ^^ Equivalent to 16px for most browsers,
    //    unless browser settings have been altered by user.
    //    DO NOT set font-sizes/line-heights in pixels; these overrule
    //    user-set browser settings (Accessibility consideration)
    // line-height: 1.5; // Override reset.css?
    font-weight: 300;
    background-color: $gray--lighter;
}

body {
    text-size-adjust: none;
    // Prevent phone & tablet devices from adjusting default browser text-size
    // when switching between portrait/landscape orientations
    // (Vendor prefixed versions provided by Autoprefixer)

    overflow-x: hidden;
    // @note: added to guard against horizontal scrollbar in Chrome
    // due to page hero requiring negative margins. ONLY apply to body
    // - applying to the `html, body` rule above prevents the back-to-top anchor
    // from working.
}

::placeholder {
    color: $placeholder-color;
}

[hidden] {
    /* stylelint-disable-next-line declaration-no-important */
    display: none !important;
}

a {
    text-decoration: underline;

    &:hover,
    &:focus {
        text-decoration: none;
    }
}

// ELEMENT FOCUS RULES
// For Accessibility reasons make sure there are styles for BOTH `:focus` AND
// `:focus-visible` on any keyboard focusable element.
// -- :focus-visible is not currently supported by Edge, IE, Opera & Safari
// -- Use something big & bold, so those with poor vision or in bright environments
//    can see it
// -- It's a feature, not a bug!
a,
button,
input,
select,
textarea {
    &:not(:disabled):focus-visible {
        outline: dotted em(1px, 16px) currentColor;
        outline-offset: em(3px, 16px);
    }
}
// The exception provided here is for elements focussed ONLY via JavaScript
// (`tabindex="-1"`), as these elements fall outside the regular page tab order
[tabindex="-1"] {
    /* stylelint-disable-next-line declaration-no-important */
    outline: none !important;
}

article,
section {
    clear: both;
}

blockquote,
figure,
pre {
    break-inside: avoid;
}

figcaption {
    @extend %text-basic;
    padding: em(24px, 16px) em(24px, 16px) em(12px, 16px);

    p:first-child {
        margin-top: 0;
    }
}

// Allow SVGs to override their inline fill property
svg path {
    fill: inherit;
}

.site-maxwidth {
    @extend %site-maxwidth;
}

.vertical-flow {
    margin-top: em(60px, 16px);
    margin-bottom: em(60px, 16px);

    @media screen and (min-width: $viewport-medium) {
        margin-top: em(72px, 16px);
        margin-bottom: em(72px, 16px);
    }

    // Reset nested flows
    &:not(.section) & {
        margin-top: initial;
        margin-bottom: initial;
    }
}

.component-flow {
    margin-top: 1em;
    margin-bottom: 1em;
}

.float--left {
    @media screen and (min-width: $viewport-narrow) {
        float: left;
        clear: left;
    }
}

.float--right {
    @media screen and (min-width: $viewport-narrow) {
        float: right;
        clear: right;
    }
}

// Screen reader text
// Hides text visually, but exposes it to assistive technology
.visually-hidden:not(:focus):not(:active) {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: 0;
    padding: 0;
    border: 0;
    clip: rect(0, 0, 0, 0);
    // ^^ Clip is deprecated CSS property that older browsers support
    clip-path: inset(100%);
    white-space: nowrap;
    overflow: hidden;
}

/* Global color schemes
 * - Used by cards & panels, etc
********************************/

.scheme--light-gray {
    --color_scheme_background: var(--color_gray--light);
    --color_scheme_text: var(--color_text);
}

.scheme--primary {
    --color_scheme_background: var(--color_primary);
    --color_scheme_text: var(--color_white);
}

.scheme--secondary {
    --color_scheme_background: var(--color_secondary);
    --color_scheme_text: var(--color_white);
}

.scheme--white {
    --color_scheme_background: var(--color_white);
    --color_scheme_text: var(--color_text);
}

.scheme--primary,
.scheme--secondary {
    a:not(.btn),
    [class*="heading--"],
    %heading--primary,
    %heading--secondary,
    %heading--tertiary {
        // IE ignores @supports, so modern browsers get this instruction
        @supports (color: var(--custom)) {
            color: inherit;
        }
    }

    .icon,
    .icon-roundal {
        // IE ignores @supports, so modern browsers get this instruction
        @supports (stroke: var(--custom)) {
            stroke: var(--color_scheme_text);
        }
    }
}
