/* Text component (Columns variant)
***********************************/

.columns:not(.columns--dividers) {
    > .text + .text {
        margin-top: -1em;

        // @todo: check this after columns rules & media queries have been confirmed
        @media screen and (min-width: 43em) {
            margin-top: initial;
        }
    }
}
