/* Main component (default)
***************************/

.main {
    position: relative;

    &[tabindex] {
        outline: 0;
    }
}
