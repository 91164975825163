/* Header component (default)
*****************************/

.header {
    color: var(--color_secondary);
}

.header-layout {
    @media screen and (min-width: $navigation-switch) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        @supports (display: grid) {
            display: grid;
            grid-template-columns: 1fr minmax(720px, 2fr);
            grid-column-gap: 1em;
        }
    }
}

.header-row {
    width: 100%;
    grid-column: -1 / 1;
}

.header-logo {
    max-width: em(210px, 16px);
    margin: em(14px, 16px) 0;
    padding: 1em 0;
    float: left;

    @media screen and (min-width: $viewport-narrowest) {
        max-width: none;
    }

    @media screen and (min-width: $navigation-switch) {
        margin: 0;
        padding: 1.5em 0;
        flex-grow: 0;
        float: none;
    }

    h1,
    h2 {
        margin: 0;
        font-size: rem(15px, 16px);
        font-weight: 300;
        line-height: 1.28;

        @media screen and (min-width: $viewport-narrower) {
            font-size: rem(22px, 16px);
        }
    }

    a {
        color: inherit;
        text-decoration: none;
    }

    span {
        display: block;
    }

    .brand-name {
        font-weight: 500;
    }
}
