/* Icon styles (FSI-brand site)
*******************************/

// .icon-highlight {
//     color: var(--color_secondary);
// }

.card-icon {
    color: var(--color_tertiary);
}
