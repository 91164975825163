/* Button styles (default)
**************************/

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
    @extend %button-nostyle;

    &:disabled {
        cursor: not-allowed;
        opacity: 0.4;
    }
}

.btn {
    display: inline-block;
    padding: em(12px, 16px) em(24px, 16px);
    color: var(--color_btn_text);
    font-weight: 400;
    text-decoration: none;

    background-color: var(--color_btn_background);
    border: solid em(2px, 16px) var(--color_btn_border);
    border-radius: em(25px, 16px);

    transition: all 300ms ease;
    overflow-wrap: anywhere;

    cursor: pointer;

    .icon {
        stroke: var(--color_btn_text);
    }
}

.link {
    display: flex;
    align-items: center;

    font-weight: 400;
    text-decoration: none;
    overflow-wrap: anywhere;

    &:hover,
    &:focus {
        color: var(--color_secondary);
        text-decoration: underline;
    }

    svg {
        width: 1em;
        height: 1em;
        margin-left: em(6px, 16px);
        flex-shrink: 0;
        stroke: currentColor;
        stroke-width: 2.5;
    }
}

.btn--tertiary {
    --color_btn_background: var(--color_text--lead);
    --color_btn_border: var(--color_text--lead);
    --color_btn_text: var(--color_white);

    &:hover,
    &:focus {
        --color_btn_background: var(--color_white);
        --color_btn_border: var(--color_text--lead);
        --color_btn_text: var(--color_text--lead);
    }
}

.btn--tertiary.outline {
    --color_btn_background: transparent;
    --color_btn_border: var(--color_text--lead);
    --color_btn_text: var(--color_text--lead);

    &:hover,
    &:focus {
        --color_btn_background: var(--color_text--lead);
        --color_btn_border: var(--color_text--lead);
        --color_btn_text: var(--color_white);
    }
}

.scheme--primary,
.scheme--secondary {
    .btn.outline {
        --color_btn_border: var(--color_white);
        --color_btn_text: var(--color_white);

        &:hover,
        &:focus {
            --color_btn_background: var(--color_white);
            --color_btn_text: var(--color_primary);
        }
    }
}
