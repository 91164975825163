/* TOC Buttons component (default)
**********************************/

.toc-btn-headings {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: em(-3px, 16px);

    @media screen and (min-width: $viewport-narrowest) {
        flex-direction: row;
    }

    a {
        @extend .btn;
        margin: em(3px, 16px);
        padding: em(6px, 16px) em(20px, 16px);
        color: var(--color_btn_text);
        font-weight: 300;
        letter-spacing: em(0.5px, 16px);

        &:hover,
        &:focus {
            text-decoration: none;
        }
    }
}
