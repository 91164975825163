/* Font-family styles (FSI-brand site)
**************************************/
// @dev, @todo: Consider:
// - Can these fonts be hosted via a service?
//   E.g. Adobe's https://use.typekit.net or Google Fonts
// - If yes, these services track usage & therefore visitors - is that ok?

@font-face {
    font-family: "suisse-intl";
    src: url(../fonts/fsi/SuisseIntl-Regular.otf) format("opentype");
    font-display: swap;
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: "suisse-intl";
    src: url(../fonts/fsi/SuisseIntl-Light.otf) format("opentype");
    font-display: swap;
    font-style: normal;
    font-weight: 300;
}
