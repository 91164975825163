/* Title component (Panel variant)
**********************************/

.panel {
    .title {
        max-width: em(680px, 16px);
        margin: 0 auto;
        text-align: center;
    }

    .title-lead {
        max-width: em(610px, 22px);
        margin: 0 auto;
    }
}

.scheme--primary,
.scheme--secondary {
    .title .heading,
    .title .heading.outline {
        color: inherit;
        border-color: inherit;
    }
}
