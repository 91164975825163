/* Title component (default)
****************************/

.title {
    .heading.underline {
        @extend %heading--underline;
    }
}

.title-lead {
    @extend %text--lead;

    p:first-child {
        margin-top: 0;
    }
}
