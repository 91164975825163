/* Tabs component (default)
***************************/

.tabs {
    [role="tablist"] {
        display: flex;
        flex-flow: column wrap;
        justify-content: center;

        margin: em(24px, 16px) em(-6px, 16px);
        padding: 0;

        color: var(--color_primary);
        list-style: none;

        @media screen and (min-width: $viewport-medium) {
            flex-direction: row;
        }

        > li {
            display: flex;
            flex-direction: column;
        }
    }

    [role="tab"] {
        margin: em(3px, 16px) em(6px, 16px);
        padding: em(18px, 16px) em(20px, 16px);
        font-size: rem(18px, 16px);

        border: solid em(1px, 16px) #d0d4d9;
        border-radius: em(10px, 16px);

        @media screen and (min-width: $viewport-medium) {
            margin-top: em(6px, 16px);
            margin-bottom: em(6px, 16px);
        }

        &:focus,
        &:hover {
            border-color: currentColor;
        }

        &[aria-selected] {
            background-color: $white;
        }
    }
}

.tab-panel:not([role="tabpanel"]) {
    margin-top: 2em;
}
