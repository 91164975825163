/* TOC component (FSI-brand site)
*********************************/

.toc-list {
    a.highlight::after {
        background-color: var(--color_tertiary);
    }
}

.toc-downloads {
    a {
        color: $fsi-turquoise--bright;
    }
}
