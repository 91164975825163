/* Back-to-top component (default)
**********************************/

.back-top {
    display: flex;
    justify-content: flex-end;

    position: sticky;
    bottom: 0;
    margin: 0;

    z-index: 1;

    a {
        display: block;
        padding: em(14px, 16px);
        color: var(--color_white);
        background-color: var(--color_primary);
        border-top-left-radius: var(--border_radius);
        border-top-right-radius: var(--border_radius);
    }

    svg {
        width: 1.4em;
        height: 1.4em;
        padding: em(2px, 16px);
        stroke-width: 3;
        transform: rotate(-90deg);
        border: solid em(2px, 16px) transparent;
        border-radius: 50%;
    }
}
