/* Columns component (default)
******************************/
$columns-minwidth: 316px;

.columns {
    --columns_number: 2;
    --columns_gap: 1em;

    grid-template-columns: repeat(auto-fit, minmax($columns-minwidth, 1fr));

    @media screen and (min-width: $viewport-wide) {
        grid-template-columns: repeat(var(--columns_number), 1fr);
    }

    @supports (display: grid) {
        display: grid;
    }
}

.columns:not(.columns--dividers) {
    gap: 1em;

    @media screen and (min-width: $viewport-wide) {
        gap: var(--columns_gap);
    }
}

// @note: repetition of columns class deliberate;
// raises specificity
.columns.columns.columns--3,
.panel .columns.columns--dividers {
    grid-template-columns: auto;

    @media screen and (min-width: $navigation-switch) {
        grid-template-columns: repeat(var(--columns_number), 1fr);
    }
}

.columns.columns--dividers {
    overflow: hidden;

    > *.column-item {
        margin: 0; // If reverting to border, margin will need to be -1px
        padding: em(36px, 16px) em(24px, 16px);
        border-radius: 0;

        // @note: currently using box-shadow (cf. brand-specific files for colours)
        // border: solid 1px currentColor;

        @media screen and (min-width: $viewport-medium) {
            padding-left: em(36px, 16px);
            padding-right: em(36px, 16px);
        }
    }
}

.columns-container{
	&.vertical-flow {
		margin-top: 0px !important;
	}
}
.columns-container.vertical-flow {
    .title + &,
    .text + & {
        margin-top: 0;
    }
}


