/* TOC component (default)
**************************/

.toc-container {
    position: relative;

    &.vertical-flow {
        margin-top: -2.5em;

        @media screen and (min-width: $navigation-switch) {
            margin-top: inherit;
        }
    }
}

.toc {
    color: #404959;
    background-color: white;
    border: solid em(1px, 16px) var(--color_heading_border);
    border-radius: var(--border_radius);
    overflow: hidden;

    .toc-list li:last-child a {
        border-bottom: 0;
    }
}

.toc-title.heading--tertiary {
    margin-bottom: 0;

    @media screen and (min-width: $navigation-switch) {
        display: none;
    }
}

.toc-button {
    display: block;
    width: 100%;
    padding: em(10px, 16px) 1em;
    font-weight: 400;
    background-color: $gray--lighter;
}

.toc-list {
    margin: 0;
    padding: 0;
    list-style-type: none;

    a {
        position: relative;
        display: block;
        color: inherit;
        text-decoration: none;
        overflow-wrap: anywhere;

        &::after {
            position: absolute;
            top: calc(50% - 4px);
            right: 30px;
            display: block;
            width: 8px;
            height: 8px;
            background-color: transparent;
            border-radius: 50%;
            opacity: 0;
            transition: all 0.3s ease;
            content: "";
        }

        &:hover,
        &:focus {
            text-decoration: underline;
        }

        &.highlight {
            &::after {
                // background-color: Set in the brand-specific SCSS file
                right: 10px;
                opacity: 1;
            }
        }
    }
}

.toc-headings {
    --border_color: #e3e6e5;

    a:not(.section-heading) {
        font-size: rem(15px, 16px);
        letter-spacing: em(0.3px, 15px);
        line-height: 1.2667;
        padding: em(6px, 15px) em(20px, 15px);
        border-bottom: dashed em(1px, 15px) var(--border_color);
    }

    a.section-heading {
        margin-top: -1px;
        padding: em(10px, 16px) 1em;
        font-weight: 400;
        background-color: $gray--lighter;
        border-top: solid em(1px, 16px) var(--border_color);
    }
}

.toc-downloads {
    padding: em(6px, 16px);
    background-color: var(--color_secondary);

    a {
        display: flex;
        align-items: center;

        padding: em(6px, 16px) em(20px, 16px);
        color: $white;
        font-weight: 400;
    }

    svg {
        margin-right: em(12px, 16px);
    }
}

.tmpl-report {
    // @edgecase solution:
    // Sticky class applied via JavaScript provided the TOC contents
    // don't overflow the window height
    //
    // - Ensures visitor can always access all nav links without having to scroll all
    //   the way to the bottom of the page (which would be counter-productive)
    .toc.sticky {
        @media screen and (min-width: $navigation-switch) {
            position: sticky;
            top: em(40px, 16px);
        }
    }
}
