/* Columns component (FSI-brand site)
*************************************/

.columns.columns--dividers {
    > *.column-item {
        .scheme--light-gray & {
            // border-color: #bac1ca;
            box-shadow: 0 0 0 em(0.5px, 16px) #bac1ca;
        }

        .scheme--primary & {
            // border-color: #7fe3c85c;
            box-shadow: 0 0 0 em(0.5px, 16px) #7fe3c85c;
        }

        .scheme--secondary & {
            // border-color: #2f6c7f;
            box-shadow: 0 0 0 em(0.5px, 16px) #2f6c7f;
        }

        .scheme--white & {
            // border-color: #c5cad1;
            box-shadow: 0 0 0 em(0.5px, 16px) #c5cad1;
        }
    }
}
