/* Table component (default)
****************************/

.table {
    padding: em(36px, 16px);
    background-color: var(--color_white);
    border-radius: var(--border_radius);
    overflow: hidden;

    h2 {
        margin-bottom: em(4px, 24px);
    }

    p {
        margin-top: 0;
    }
}

table,
.row-group {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
}

.row-group {
    margin: 1.5em 0;

    dl {
        display: flex;
        flex-wrap: wrap;
        margin: 0;
    }

    dt,
    dd {
        flex: 0 0 calc(50% - 2px);
        margin: 0 1px;
    }
}

.row-heading {
    margin-bottom: 0;
}

caption {
    text-align: initial;
}

th,
.row-heading,
.row-group dt {
    color: var(--color_secondary);
    font-weight: 400;
}

th,
td,
.row-heading,
.row-group dt,
.row-group dd {
    padding: 0.5em 1em;
    text-align: left; // Was using `initial`, but IE11 had issues
    line-height: 1.5;
}

tbody,
.row-group {
    font-size: rem(14px, 16px);
}

thead th:first-child,
tbody th,
tfoot th,
.row-heading,
.row-group dt {
    padding-left: 0;
}

thead th:last-child,
td:last-child,
.row-heading,
.row-group dd {
    padding-right: 0;
}

tr:not(:last-child) td,
tr:not(:last-child) th,
.row-group dt,
.row-group dd {
    border-bottom: dashed em(1px, 16px) $gray--light;
}

thead th,
thead td,
.row-heading {
    border-bottom: solid em(1px, 16px) var(--color_secondary);
}

tfoot td,
tfoot th {
    border-top: solid em(1px, 16px) var(--color_secondary);
}

// Overflowing table made horizontally scrollable
// Detected & controlled via client-side JavaScript
.table-overflow {
    margin: 0;

    &[tabindex="0"] {
        overflow-x: scroll; // For iOS, smooth scrolling has to be `scroll`, not `auto`.
        -webkit-overflow-scrolling: touch;

        caption {
            max-width: 98vw;
        }

        .scroll-notification {
            margin: 0;
        }
    }
}

// Switch between description list & table output
.table--bestfit {
    .tabular-data {
        display: none;

        @media screen and (min-width: $viewport-medium) {
            display: block;
        }
    }

    .list-data {
        @media screen and (min-width: $viewport-medium) {
            display: none;
        }
    }
}
