/* Various components (Panel variant)
*************************************/
// E.g. applies to Infogram, Image, Video

.panel {
    .panel-caveat {
        background-color: inherit;

        figcaption {
            padding-left: 0;
            padding-right: 0;
            padding-bottom: 0;

            p:last-child {
                margin-bottom: 0;
            }
        }
    }

    // When a direct descendant...
    > .panel-caveat {
        // Negative top/bottom margins 'reduce' panel padding to 24px
        margin: em(-36px, 16px) 0;

        @media screen and (min-width: $viewport-medium) {
            margin-top: em(-48px, 16px);
            margin-bottom: em(-48px, 16px);
        }
    }
}
