/* Aside/Pull Quote component (default)
***************************************/

.aside-container {
    &.float--left,
    &.float--right {
        @media screen and (min-width: $viewport-narrow) {
            max-width: em(320px, 16px);
            margin: em(6px, 16px) 0 em(20px, 16px);
        }
    }

    &.float--left {
        @media screen and (min-width: $viewport-narrow) {
            margin-right: em(30px, 16px);
        }
    }

    &.float--right {
        @media screen and (min-width: $viewport-narrow) {
            margin-left: em(30px, 16px);
        }
    }
}

.aside {
    font-size: rem(18px, 16px);
    margin: 0;
    padding: em(36px, 18px);
    background-color: $white;
    border-left: solid em(5px, 18px) var(--color_tertiary);
}
