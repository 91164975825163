/* Card Hero component (default)
********************************/

.card-hero {
    --color_scheme_background: var(--color_secondary);
    --color_scheme_text: var(--color_white);

    .card-inset {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        position: relative;
        min-height: 450px;
        padding: 0;

        color: var(--color_scheme_text);
        background-color: var(--color_scheme_background);
    }

    .card-image {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        img {
            @extend %image-objectfit--fallback;
            @extend %image-objectfit;
        }
    }

    .card-text,
    .card-description {
        flex-grow: 0;
    }

    .card-text {
        position: relative;
        max-width: em(620px, 16px);
        padding: em(24px, 16px);
    }

    .card-text--outline {
        /* prettier-ignore */
        text-shadow:
            -1px -1px 0 #555,
            1px -1px 0 #555,
            -1px 1px 0 #555,
            1px 1px 0 #555;
    }

    .card-description {
        p {
            font-size: rem(32px, 16px);
            font-weight: 400;
        }

        a {
            text-decoration: underline;

            &:hover,
            &:focus {
                text-decoration: none;
            }
        }
    }
}

.card-hero .scheme--light-gray,
.card-hero .scheme--white {
    .card-text--outline {
        /* prettier-ignore */
        text-shadow:
            -1px -1px 0  #eee,
            1px -1px 0  #eee,
            -1px 1px 0  #eee,
            1px 1px 0  #eee;
    }
}
