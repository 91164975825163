/* Video component (default)
****************************/

// .video-container {}

.video {
    margin: 0;
    background-color: $white;
}

.video-responsive {
    position: relative;
    max-width: 100%;

    // Only allow the video box to take space on pages when JavaScript is available
    // Brightcove renders a black box when no JS is available, so 'hide' it
    .js & {
        > .ratio-16x9 {
            padding-top: 56.25%;
        }

        > .ratio-4x3 {
            padding-top: 75%;
        }
    }

    iframe {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: none;
    }
}
