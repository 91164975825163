/* Text component (Panel variant)
*********************************/

.panel {
    .text {
        a {
            // Ensure plain panel links can easily be distinguished
            // from other text formatting, e.g. bold text
            text-decoration: underline;

            &:hover,
            &:focus {
                text-decoration: none;
            }
        }
    }
}

.scheme--primary,
.scheme--secondary {
    .text {
        h1,
        h2,
        h3,
        h4,
        a {
            color: inherit;
        }

        .underline {
            border-color: inherit;
        }
    }
}
