/* Panel component (default)
****************************/

.panel-container {
    --color_scheme_text: var(--color_text);
    --color_scheme_background: var(--color_gray--light);

    // When panels stack together, amend the their vertical-flow
    & + & {
        margin-top: em(-40px, 16px);
    }
}

.panel {
    padding: em(60px, 16px) em(24px, 16px);
    color: var(--color_scheme_text);
    background-color: var(--color_scheme_background);
    border-radius: var(--border_radius);
    overflow: hidden;

    @media screen and (min-width: $viewport-medium) {
        padding-top: em(72px, 16px);
        padding-bottom: em(72px, 16px);
    }

    &.panel--thin {
        padding-top: em(36px, 16px);
        padding-bottom: em(36px, 16px);
    }
}

// Ensure that content components inside 'invisible' panels
// align with other content above/below it
*:not(.columns) {
    .panel.scheme--light-gray {
        padding-left: 0;
        padding-right: 0;
    }
}
