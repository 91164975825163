/* TOC Buttons component (FSI-brand site)
*****************************************/

.toc-btn-headings {
    a {
        --color_btn_background: #b3dcd9;
        --color_btn_border: var(--color_secondary);
        --color_btn_text: var(--color_secondary);

        &:hover,
        &:focus {
            --color_btn_background: var(--color_primary);
            --color_btn_text: var(--color_white);
        }
    }
}
