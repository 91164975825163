/* Article component (default)
******************************/

.main {
    > .article.landmark {
        margin-bottom: em(60px, 16px);

        @media screen and (min-width: $viewport-medium) {
            margin-bottom: em(72px, 16px);
        }
    }
}

// Report template layout
.tmpl-report {
    .main > .article.landmark {
        @media screen and (min-width: $navigation-switch) {
            @supports (display: grid) {
                display: grid;
                grid-template-columns: minmax(auto, 300px) 1fr;
                column-gap: em(60px, 16px);

                /* prettier-ignore */
                grid-template-areas:
                "hero hero"
                "rail content";
            }
        }
    }

    .article.landmark {
        > .hero-container.landmark {
            grid-area: hero;
        }

        > .toc-container {
            grid-area: rail;
        }

        > .container.report-content {
            grid-area: content;
        }

        > .toc-container,
        > .container.report-content .section:first-child {
            margin-top: em(-24px, 16px);

            @media screen and (min-width: $navigation-switch) {
                margin-top: 0;
            }
        }
    }
}
