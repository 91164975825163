/* Cookies component (default)
******************************/

.cookie-banner {
	display: none;
    margin: 0.5em 0;
    padding: 1.5em;
    background-color: white;
    border: solid em(2px, 16px) var(--color_gray--light);
    border-radius: var(--border_radius);
    overflow: hidden;

    h2 {
        margin: 0;
        font-size: 1rem;
        text-transform: uppercase;
    }

    p {
        margin: 0.5em 0 0;
    }
}

.cookie-disclaimer {
    a {
        @extend %link-plain;
    }
}

p.cookie-actions {
    display: flex;
    justify-content: space-between;
    margin-left: -0.5em;
    margin-right: -0.5em;
    font-size: smaller;

    > * {
        flex-grow: 1;
        margin: 0.5em 0.5em 0;
        text-align: center;
    }
}
