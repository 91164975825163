/* OneTrust Consent Banner Component (default)
*******************************/

#onetrust-banner-sdk.otCenterRounded {
    display: none !important;
    width: 80vw !important;
    max-width: max-content !important;
    left: 0em !important;
    @media only screen and (max-width: 425px) {
        width: 95vw !important;
    }
}

#onetrust-banner-sdk.ot-bnr-w-logo .ot-bnr-logo {
    height: 40px !important;
    width: auto !important;
}

#onetrust-banner-sdk #onetrust-policy-text,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-desc {
    color: #475966 !important;
    font-size: 16px !important;
    line-height: 25.6px !important;
}

#onetrust-banner-sdk #onetrust-policy-title,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-title {
    font-size: 24px !important;
}

#onetrust-banner-sdk #onetrust-reject-all-handler,
#onetrust-pc-sdk .ot-pc-refuse-all-handler,
#onetrust-banner-sdk #onetrust-pc-btn-handler {
    color: #002a4b !important;
    background-color: #ffffff !important;
    border-color: #002a4b !important;
    font-weight: 500 !important;
    border-radius: 2.5em !important;
}

#onetrust-banner-sdk #onetrust-accept-btn-handler,
#onetrust-banner-sdk #onetrust-reject-all-handler,
#onetrust-banner-sdk #onetrust-pc-btn-handler,
#onetrust-pc-sdk #accept-recommended-btn-handler {
    font-size: 16px !important;
    font-weight: 500 !important;
    border-radius: 2.5em !important;
}

#onetrust-banner-sdk #onetrust-reject-all-handler:hover,
#onetrust-banner-sdk #onetrust-pc-btn-handler:hover,
#onetrust-pc-sdk .ot-pc-refuse-all-handler:hover {
    color: #ffffff !important;
    background-color: #3699c9 !important;
    border-color: #3699c9 !important;
}

#onetrust-pc-sdk .save-preference-btn-handler {
    border-radius: 2.5em !important;
}

@media only screen and (max-width: 768px) {
    #onetrust-banner-sdk .banner-actions-container {
        display: flex !important;
        flex-wrap: wrap !important;
    }

    #onetrust-banner-sdk #onetrust-reject-all-handler {
        order: 2 !important;
    }
}

#onetrust-pc-sdk .ot-cat-grp .ot-always-active {
    color: #002a4b !important;
}

#onetrust-pc-sdk .ot-tgl input:checked+.ot-switch .ot-switch-nob {
    border: 1px solid #002a4b !important;
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    
    &::before{
	    height: 21px;
	    width: 21px;
	    background-color: #002a4b !important;
	    border-color: #002a4b !important;
    }
}

#onetrust-pc-sdk .ot-pc-header {
    padding: 10px 0 10px 10px !important;
}

#onetrust-pc-sdk .ot-pc-scrollbar::-webkit-scrollbar-thumb {
    border-radius: 0px !important;
}

#onetrust-banner-sdk a:hover,
#onetrust-pc-sdk a:hover {
    color: #cc181f !important;
    text-decoration: unset !important;
}

.onetrust-banner-sdk-open {
    overflow: hidden;
}

a.ot-opt-out-notice-link {
    color: #002a4b !important;
}

button#ot-sdk-btn.ot-sdk-show-settings {
    background-color: unset !important;
    color: #ffffff !important;
    font-size: 1rem !important;
    line-height: 1.6em !important;
    letter-spacing: 0.32px !important;
    border: none !important;
    padding: 0 !important;
}

button#ot-sdk-btn.ot-sdk-show-settings:hover {
    color: #ffffff !important;
    text-decoration: underline;
}

#onetrust-pc-sdk .ot-pc-footer-logo,
.hide-one-trust-banner #onetrust-banner-sdk.otCenterRounded {
    display: none !important;
}

.hide-one-trust-banner .onetrust-pc-dark-filter {
    position: unset !important;
}