/* Board component (default)
****************************/

// .board-container {}

.board {
    position: relative;
    letter-spacing: em(0.5px, 16px);

    break-inside: avoid;
    border-radius: var(--border_radius);
    overflow: hidden;

    h3 {
        @extend %heading--tertiary;
    }
}

.board-image {
    @media screen and (min-width: $viewport-medium) {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    img {
        @extend %image-objectfit--fallback;
        @extend %image-objectfit;
    }
}

.board-text {
    position: relative;
    padding: em(36px, 16px);

    color: var(--color_scheme_text);
    background-color: $white; // IE11 fallback
    background-color: var(--color_scheme_background);

    @media screen and (min-width: $viewport-medium) {
        max-width: 50%;
        margin-left: 50%;
    }

    .board-container:nth-of-type(2n) & {
        @media screen and (min-width: $viewport-medium) {
            margin-left: 0;
        }
    }
}

.board-actions {
    padding: 0;
    list-style: none;

    li + li {
        margin-top: em(12px, 16px);
    }

    li a {
        @extend %link-plain;
        display: inline-flex;
        font-weight: 400;
    }

    svg {
        width: 1em;
        height: 1em;

        margin-top: em(4px, 16px);
        margin-left: em(4px, 16px);

        flex-shrink: 0;
    }
}

.board-cta {
    margin: 0;

    @media screen and (min-width: $viewport-narrower) {
        text-align: right;
    }
}
