/* Card Profile component (default)
***********************************/

.card-profile {
    .card-inset {
        align-items: center;
    }

    .card-image {
        margin-bottom: em(24px, 16px);
        overflow: hidden;

        img {
            width: em(172px, 16px);
            height: em(172px, 16px);
            border-radius: 50%;

            object-fit: cover;
        }
    }

    .card-text {
        max-width: 60ch;
    }

    .card-title,
    .card-credential {
        text-align: center;
    }

    .card-credential {
        margin: 0 0 1em;
        font-weight: 400;
        letter-spacing: em(1px, 16px);
    }

    .card-description {
        padding-top: em(12px, 16px);
        border-top: solid em(1px, 16px) $gray--lighter;
    }

    .card-cta {
        padding-top: 1em;
    }
}
