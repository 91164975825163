@charset "UTF-8";
/* SCSS MANIFEST (FSI-branded site)
***********************************/
/* Sass functions
*****************/
/* Sass mixins
**************/
/* CSS/Sass variables
*********************/
/* CSS custom properties
************************/
/* CSS/Sass variables (English/FSI-brand site)
**********************************************/
/* CSS custom properties
************************/
:root {
  --border_radius: 0.625em;
  --color_white: #fff;
  --color_gray: #606e7f;
  --color_gray--light: #eef1f4;
  --color_primary: #00727d;
  --color_secondary: #022855;
  --color_tertiary: #60bdb3;
  --color_text: #022855;
  --color_text--lead: #606e7f;
  --color_link: #00727d;
  --color_hero--lead: #deede7;
  --color_border: #60bdb3;
  --color_heading_border: #c9d2dc;
  --color_img_border: #00727d;
  --color_footer_text: #fff;
  --color_footer_background: #00727d;
  --color_footer_border: #00727d;
  --color_btn_background: transparent;
  --color_btn_border: currentColor;
  --color_btn_text: currentColor;
  --mql_navigation_switch: 61.875em;
}

/* Font-family styles (FSI-brand site)
**************************************/
@font-face {
  font-family: "suisse-intl";
  src: url(../fonts/fsi/SuisseIntl-Regular.otf) format("opentype");
  font-display: swap;
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "suisse-intl";
  src: url(../fonts/fsi/SuisseIntl-Light.otf) format("opentype");
  font-display: swap;
  font-style: normal;
  font-weight: 300;
}
/* CSS animation styles
***********************/
/* SCSS placeholder templates
*****************************/
button,
input[type=button],
input[type=reset],
input[type=submit] {
  margin: 0;
  padding: 0;
  appearance: none;
  color: inherit;
  background-color: transparent;
  border: none;
  font: inherit;
  text-align: inherit;
  cursor: pointer;
}

.text h1,
.text h2,
.heading--primary {
  margin: 0 0 0.5em;
  font-size: 2rem;
  font-weight: 300;
  line-height: 1.1875;
  letter-spacing: normal;
}

.text h3,
.heading--secondary {
  font-size: 1.5rem;
  margin: 0 0 0.4166666667em;
  font-weight: 300;
  line-height: 1.263;
  letter-spacing: normal;
}

.board h3, .text h4,
.heading--tertiary {
  font-size: 1.1875rem;
  margin: 0 0 0.6315789474em;
  font-weight: 400;
  line-height: 1.263;
  letter-spacing: normal;
}

.title .heading.underline, .text .underline {
  padding-bottom: 0.375em;
  border-bottom: solid 1px var(--color_heading_border);
}

.teaser-image img, .card-hero .card-image img, .board-image img, .hero-image img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  max-width: none;
  height: auto;
  transform: translate(-50%, -50%);
}

@supports (object-fit: cover) {
  .teaser-image img, .card-hero .card-image img, .board-image img, .hero-image img {
    position: static;
    width: 100%;
    max-width: none;
    height: 100%;
    object-fit: cover;
    transform: none;
  }
}

.teaser-description a, .card-description a, .board-actions li a, .text a, .list a, .hero-cta .hero-link, .cookie-disclaimer a {
  color: var(--color_link);
  font-weight: 600;
  text-decoration: none;
  overflow-wrap: anywhere;
}
.teaser-description a:hover, .card-description a:hover, .board-actions li a:hover, .text a:hover, .list a:hover, .hero-cta .hero-link:hover, .cookie-disclaimer a:hover, .teaser-description a:focus, .card-description a:focus, .board-actions li a:focus, .text a:focus, .list a:focus, .hero-cta .hero-link:focus, .cookie-disclaimer a:focus {
  text-decoration: underline;
}

.site-maxwidth {
  width: 100%;
  max-width: 80em;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1.5em;
  padding-right: 1.5em;
}

.card-description, figcaption {
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.03125em;
}

.title-lead, .text .text--lead {
  font-size: 1.375rem;
  line-height: 1.363;
}

/* Placeholder styles (FSI-brand site)
**************************************/
.text h1,
.text h2,
.heading--primary {
  color: var(--color_primary);
}

.text h3,
.heading--secondary {
  color: var(--color_primary);
}

.board h3, .text h4,
.heading--tertiary {
  color: var(--color_primary);
}

/**
 * CSS reset provided via Mozilla/JenSimmons
 * -- https://github.com/jensimmons/cssremedy
 * 
 * This reset contains file contents from:
 * - remedy.css
 * - reminders.css
 */
/* Global Remedies
******************/
/* Use border-box by default, globally */
*,
::before,
::after {
  box-sizing: border-box;
}

/*
* Consistent line spacing...
* CSS Inline Layout Module Level 3: https://drafts.csswg.org/css-inline-3/#line-sizing-property
*/
html {
  line-sizing: normal;
}

/* Remove the tiny space around the edge of the page */
body {
  margin: 0;
}

/* Headings
***********/
/* Switch to rem units for headings */
/* @@@ Initial values are based on existing browser defaults */
h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.5rem;
}

h3 {
  font-size: 1.17rem;
}

h4 {
  font-size: 1rem;
}

h5 {
  font-size: 0.83rem;
}

h6 {
  font-size: 0.67rem;
}

/* Keep h1 margins consistent, even when nested */
h1 {
  margin: 0.67em 0;
}

/* Typography
*************/
/* Overflow by default is bad */
pre {
  white-space: pre-wrap;
}

/*
* 1. Solid, thin horizontal rules
* 2. Remove Firefox `color: gray`
* 3. Remove default `1px` height, and common `overflow: hidden`
*/
hr {
  border-style: solid;
  border-width: 1px 0 0;
  color: inherit;
  height: 0;
  overflow: visible;
}

/* Embedded Elements
********************/
/*
* 1. Block display is usually what we want
* 2. Remove strange space-below when inline
* 3. Responsive by default
*/
img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block;
  vertical-align: middle;
  max-width: 100%;
}

/*
* Maintain intrinsic aspect ratios when `max-width` is applied
* (iframe, embed, and object have no intrinsic ratio, set height explicitly)
*/
img,
svg,
video,
canvas {
  height: auto;
}

/*
* There is no good reason elements default to 300px,
* and audio files are unlikely to come with a width attribute
*/
audio {
  width: 100%;
}

/* Old Browsers
***************/
/* Remove the border on images inside links in IE 10 and earlier */
img {
  border-style: none;
}

/* Hide the overflow in IE 10 and earlier */
svg {
  overflow: hidden;
}

/* Default block display on HTML5 elements */
article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

/*
* 1. Add the correct box sizing in IE 10
* 2. Remove the padding in IE 10
*/
[type=checkbox],
[type=radio] {
  box-sizing: border-box;
  padding: 0;
}

/**
 * CSS reminders file contents
 * -- https://raw.githubusercontent.com/jensimmons/cssremedy/master/css/reminders.css
 */
/*
* These are not universally recommended remedies,
* but they are good to consider,
* and they might work for you.
*/
/* Nav Lists
************/
/*
* List styling is not usually desired in navigation,
* but this also removes list-semantics for screen-readers
* See: https://github.com/mozdevs/cssremedy/issues/15
*/
nav ul {
  list-style: none;
}

/*
  * 1. Add zero-width-space to prevent VoiceOver disable
  * 2. Absolute position ensures no extra space
  * See: https://unfetteredthoughts.net/2017/09/26/voiceover-and-list-style-type-none/
  */
nav li:before {
  content: "​";
  position: absolute;
}

/* Reduced Motion
  *****************/
/*
  * 1. Immediately jump any animation to the end point
  * 2. Remove transitions & fixed background attachment
  * See: https://github.com/mozdevs/cssremedy/issues/11
  */
@media (prefers-reduced-motion: reduce) {
  *,
::before,
::after {
    animation-delay: -1ms !important;
    animation-duration: 1ms !important;
    animation-iteration-count: 1 !important;
    background-attachment: initial !important;
    scroll-behavior: auto !important;
    transition-delay: 0s !important;
    transition-duration: 0s !important;
  }
}
/* Line Heights
  ***************/
/*
  * The default `normal` line-height is tightly spaced, but takes font-metrics into account.
  * Looser spacing may improve readability, but may cause problems in some scripts.
  * See: https://github.com/mozdevs/cssremedy/issues/7
  */
html {
  line-height: 1.5;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.25;
}

caption,
figcaption,
label,
legend {
  line-height: 1.375;
}

/* Global styles
****************/
html,
body {
  height: 100%;
  color: var(--color_text);
  font-family: "suisse-intl", Helvetica, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 300;
  background-color: #eef1f4;
}

body {
  text-size-adjust: none;
  overflow-x: hidden;
}

::placeholder {
  color: #666;
}

[hidden] {
  /* stylelint-disable-next-line declaration-no-important */
  display: none !important;
}

a {
  text-decoration: underline;
}
a:hover, a:focus {
  text-decoration: none;
}

a:not(:disabled):focus-visible,
button:not(:disabled):focus-visible,
input:not(:disabled):focus-visible,
select:not(:disabled):focus-visible,
textarea:not(:disabled):focus-visible {
  outline: dotted 0.0625em currentColor;
  outline-offset: 0.1875em;
}

[tabindex="-1"] {
  /* stylelint-disable-next-line declaration-no-important */
  outline: none !important;
}

article,
section {
  clear: both;
}

blockquote,
figure,
pre {
  break-inside: avoid;
}

figcaption {
  padding: 1.5em 1.5em 0.75em;
}
figcaption p:first-child {
  margin-top: 0;
}

svg path {
  fill: inherit;
}

.vertical-flow {
  margin-top: 3.75em;
  margin-bottom: 3.75em;
}
@media screen and (min-width: 48em) {
  .vertical-flow {
    margin-top: 4.5em;
    margin-bottom: 4.5em;
  }
}
.vertical-flow:not(.section) .vertical-flow {
  margin-top: initial;
  margin-bottom: initial;
}

.component-flow {
  margin-top: 1em;
  margin-bottom: 1em;
}

@media screen and (min-width: 38.75em) {
  .float--left {
    float: left;
    clear: left;
  }
}

@media screen and (min-width: 38.75em) {
  .float--right {
    float: right;
    clear: right;
  }
}

.visually-hidden:not(:focus):not(:active) {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: 0;
  padding: 0;
  border: 0;
  clip: rect(0, 0, 0, 0);
  clip-path: inset(100%);
  white-space: nowrap;
  overflow: hidden;
}

/* Global color schemes
 * - Used by cards & panels, etc
********************************/
.scheme--light-gray {
  --color_scheme_background: var(--color_gray--light);
  --color_scheme_text: var(--color_text);
}

.scheme--primary {
  --color_scheme_background: var(--color_primary);
  --color_scheme_text: var(--color_white);
}

.scheme--secondary {
  --color_scheme_background: var(--color_secondary);
  --color_scheme_text: var(--color_white);
}

.scheme--white {
  --color_scheme_background: var(--color_white);
  --color_scheme_text: var(--color_text);
}

@supports (color: var(--custom)) {
  .scheme--primary a:not(.btn),
.scheme--primary [class*=heading--],
.scheme--primary .text h1,
.text .scheme--primary h1,
.scheme--primary .text h2,
.text .scheme--primary h2,
.scheme--primary .heading--primary,
.scheme--primary .text h3,
.text .scheme--primary h3,
.scheme--primary .heading--secondary,
.scheme--primary .board h3,
.board .scheme--primary h3,
.scheme--primary .text h4,
.text .scheme--primary h4,
.scheme--primary .heading--tertiary,
.scheme--secondary a:not(.btn),
.scheme--secondary [class*=heading--],
.scheme--secondary .text h1,
.text .scheme--secondary h1,
.scheme--secondary .text h2,
.text .scheme--secondary h2,
.scheme--secondary .heading--primary,
.scheme--secondary .text h3,
.text .scheme--secondary h3,
.scheme--secondary .heading--secondary,
.scheme--secondary .board h3,
.board .scheme--secondary h3,
.scheme--secondary .text h4,
.text .scheme--secondary h4,
.scheme--secondary .heading--tertiary {
    color: inherit;
  }
}
@supports (stroke: var(--custom)) {
  .scheme--primary .icon,
.scheme--primary .icon-roundal,
.scheme--secondary .icon,
.scheme--secondary .icon-roundal {
    stroke: var(--color_scheme_text);
  }
}

/* Icon styles (default)
************************/
.icon {
  width: 1.5em;
  height: 1.5em;
  fill: none;
  stroke: currentColor;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2;
}

.icon-roundal {
  width: 60px;
  height: 60px;
  stroke-miterlimit: 10;
}

.icon--menu {
  width: 1em;
  height: 1em;
}

.icon--pdf,
.icon--speech {
  fill: currentColor;
  stroke: none;
}

/* Icon styles (FSI-brand site)
*******************************/
.card-icon {
  color: var(--color_tertiary);
}

/* Button styles (default)
**************************/
button:disabled,
input[type=button]:disabled,
input[type=reset]:disabled,
input[type=submit]:disabled {
  cursor: not-allowed;
  opacity: 0.4;
}

.btn, .toc-btn-headings a {
  display: inline-block;
  padding: 0.75em 1.5em;
  color: var(--color_btn_text);
  font-weight: 400;
  text-decoration: none;
  background-color: var(--color_btn_background);
  border: solid 0.125em var(--color_btn_border);
  border-radius: 1.5625em;
  transition: all 300ms ease;
  overflow-wrap: anywhere;
  cursor: pointer;
}
.btn .icon, .toc-btn-headings a .icon {
  stroke: var(--color_btn_text);
}

.link {
  display: flex;
  align-items: center;
  font-weight: 400;
  text-decoration: none;
  overflow-wrap: anywhere;
}
.link:hover, .link:focus {
  color: var(--color_secondary);
  text-decoration: underline;
}
.link svg {
  width: 1em;
  height: 1em;
  margin-left: 0.375em;
  flex-shrink: 0;
  stroke: currentColor;
  stroke-width: 2.5;
}

.btn--tertiary {
  --color_btn_background: var(--color_text--lead);
  --color_btn_border: var(--color_text--lead);
  --color_btn_text: var(--color_white);
}
.btn--tertiary:hover, .btn--tertiary:focus {
  --color_btn_background: var(--color_white);
  --color_btn_border: var(--color_text--lead);
  --color_btn_text: var(--color_text--lead);
}

.btn--tertiary.outline {
  --color_btn_background: transparent;
  --color_btn_border: var(--color_text--lead);
  --color_btn_text: var(--color_text--lead);
}
.btn--tertiary.outline:hover, .btn--tertiary.outline:focus {
  --color_btn_background: var(--color_text--lead);
  --color_btn_border: var(--color_text--lead);
  --color_btn_text: var(--color_white);
}

.scheme--primary .btn.outline, .scheme--primary .toc-btn-headings a.outline, .toc-btn-headings .scheme--primary a.outline,
.scheme--secondary .btn.outline,
.scheme--secondary .toc-btn-headings a.outline,
.toc-btn-headings .scheme--secondary a.outline {
  --color_btn_border: var(--color_white);
  --color_btn_text: var(--color_white);
}
.scheme--primary .btn.outline:hover, .scheme--primary .toc-btn-headings a.outline:hover, .toc-btn-headings .scheme--primary a.outline:hover, .scheme--primary .btn.outline:focus, .scheme--primary .toc-btn-headings a.outline:focus, .toc-btn-headings .scheme--primary a.outline:focus,
.scheme--secondary .btn.outline:hover,
.scheme--secondary .toc-btn-headings a.outline:hover,
.toc-btn-headings .scheme--secondary a.outline:hover,
.scheme--secondary .btn.outline:focus,
.scheme--secondary .toc-btn-headings a.outline:focus,
.toc-btn-headings .scheme--secondary a.outline:focus {
  --color_btn_background: var(--color_white);
  --color_btn_text: var(--color_primary);
}

/* Button styles (FSI-brand site)
*********************************/
.link {
  color: var(--color_secondary);
}
.link:hover, .link:focus {
  color: #00727d;
}

.btn--hero {
  --color_btn_background: var(--color_tertiary);
  --color_btn_border: var(--color_tertiary);
  --color_btn_text: var(--color_secondary);
}
.btn--hero:hover, .btn--hero:focus {
  --color_btn_background: #fff;
  --color_btn_border: #fff;
}

.btn--primary:hover, .btn--primary:focus,
.btn--primary.outline:hover,
.btn--primary.outline:focus,
.btn--secondary:hover,
.btn--secondary:focus,
.btn--secondary.outline:hover,
.btn--secondary.outline:focus {
  --color_btn_background: var(--color_tertiary);
  --color_btn_border: var(--color_tertiary);
  --color_btn_text: var(--color_secondary);
}

.btn--primary {
  --color_btn_background: var(--color_secondary);
  --color_btn_border: var(--color_secondary);
  --color_btn_text: var(--color_white);
}

.btn--primary.outline {
  --color_btn_background: transparent;
  --color_btn_border: var(--color_secondary);
  --color_btn_text: var(--color_secondary);
}

.btn--secondary {
  --color_btn_background: var(--color_primary);
  --color_btn_border: var(--color_primary);
  --color_btn_text: var(--color_white);
}

.btn--secondary.outline {
  --color_btn_background: transparent;
  --color_btn_border: var(--color_tertiary);
  --color_btn_text: var(--color_secondary);
}

/* Skip-to-main component manifest file 
 * (FSI-brand site)
***************************************/
/* Skip-to-main component (default)
***********************************/
.skip-to-main {
  position: absolute;
}

a.skip-link {
  display: inline-block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1em;
  color: white;
  background-color: var(--color_primary);
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  transform: translateY(-10em);
  transition: transform 0.2s ease-in-out;
  z-index: 20;
}
a.skip-link:focus, a.skip-link:focus-visible {
  transform: translateY(0);
  outline-offset: -0.0625em;
}

/* Skip-to-main component (FSI-brand site)
******************************************/
/* Page component manifest file 
 * (FSI-brand site)
*******************************/
/* Page component (default)
***************************/
@supports (display: grid) {
  body:not(.tmpl-blank) .sticky-footer {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto 1fr auto;
    min-height: 100%;
  }
}

/* Page component (FSI-brand site)
**********************************/
/* Header component manifest file 
 * (FSI-brand site)
*********************************/
/* Header component (default)
*****************************/
.header {
  color: var(--color_secondary);
}

@media screen and (min-width: 61.875em) {
  .header-layout {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
  @supports (display: grid) {
    .header-layout {
      display: grid;
      grid-template-columns: 1fr minmax(720px, 2fr);
      grid-column-gap: 1em;
    }
  }
}

.header-row {
  width: 100%;
  grid-column: -1/1;
}

.header-logo {
  max-width: 13.125em;
  margin: 0.875em 0;
  padding: 1em 0;
  float: left;
}
@media screen and (min-width: 23em) {
  .header-logo {
    max-width: none;
  }
}
@media screen and (min-width: 61.875em) {
  .header-logo {
    margin: 0;
    padding: 1.5em 0;
    flex-grow: 0;
    float: none;
  }
}
.header-logo h1,
.header-logo h2 {
  margin: 0;
  font-size: 0.9375rem;
  font-weight: 300;
  line-height: 1.28;
}
@media screen and (min-width: 30em) {
  .header-logo h1,
.header-logo h2 {
    font-size: 1.375rem;
  }
}
.header-logo a {
  color: inherit;
  text-decoration: none;
}
.header-logo span {
  display: block;
}
.header-logo .brand-name {
  font-weight: 500;
}

/* Header component (FSI-brand site)
************************************/
.header-logo .brand-name {
  color: var(--color_primary);
}

/* Cookies component manifest file 
 * (FSI-brand site)
**********************************/
/* Cookies component (default)
******************************/
.cookie-banner {
  display: none;
  margin: 0.5em 0;
  padding: 1.5em;
  background-color: white;
  border: solid 0.125em var(--color_gray--light);
  border-radius: var(--border_radius);
  overflow: hidden;
}
.cookie-banner h2 {
  margin: 0;
  font-size: 1rem;
  text-transform: uppercase;
}
.cookie-banner p {
  margin: 0.5em 0 0;
}

p.cookie-actions {
  display: flex;
  justify-content: space-between;
  margin-left: -0.5em;
  margin-right: -0.5em;
  font-size: smaller;
}
p.cookie-actions > * {
  flex-grow: 1;
  margin: 0.5em 0.5em 0;
  text-align: center;
}

/* Cookies component (FSI-brand site)
*************************************/
/* Navigation component manifest file 
 * (FSI-brand site)
*************************************/
/* Navigation component (default)
*********************************/
.navigation-container .btn-menu {
  margin: 1.375em 0 1em 1em;
  padding: 1em;
  color: var(--color_primary);
  border: solid 0.0625em var(--color_primary);
  float: right;
}
@media screen and (min-width: 30em) {
  .navigation-container .btn-menu {
    margin-top: 1.4em;
    padding: 1.5em;
  }
}
@media screen and (min-width: 61.875em) {
  .navigation-container .btn-menu {
    display: none;
  }
}

.navigation {
  margin-left: -1.5em;
  margin-right: -1.5em;
  background-color: #fff;
  clear: both;
  max-height: 1000px;
  opacity: 1;
  transition: all 0.4s ease;
}
@media screen and (min-width: 61.875em) {
  .navigation {
    margin: 0;
    background-color: transparent;
  }
}
.navigation > ul {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 1em 1em 1.5em 1em;
}
@media screen and (min-width: 61.875em) {
  .navigation > ul {
    flex-flow: row wrap;
    justify-content: flex-end;
    align-items: center;
    padding: 1.5em 0;
  }
}
.navigation > ul > li {
  flex-grow: 0;
}
.navigation a {
  font-weight: 400;
  letter-spacing: 0.03125em;
  text-decoration: none;
}
.navigation a:not(.btn) {
  position: relative;
  display: block;
  margin: 0.5em 0;
  padding: 0.375em 0.1875em;
  color: inherit;
}
@media screen and (min-width: 61.875em) {
  .navigation a:not(.btn) {
    margin-left: 0.5625em;
    margin-right: 0.5625em;
  }
}
.navigation a:not(.btn):hover span::before, .navigation a:not(.btn):focus span::before {
  transform: translate(-50%, 0) scaleX(1);
}
.navigation a:not(.btn) span::before {
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 100%;
  height: 0.1875em;
  background-color: currentColor;
  transform-origin: center;
  transform: translate(-50%, 0) scaleX(0);
  transition: transform 0.2s ease-in-out;
  content: "";
}
.navigation a.btn, .navigation .toc-btn-headings a, .toc-btn-headings .navigation a {
  margin: 0.5em 0;
  padding: 0.5em 1em;
}
@media screen and (min-width: 61.875em) {
  .navigation a.btn, .navigation .toc-btn-headings a, .toc-btn-headings .navigation a {
    margin: 0 0 0 0.5em;
  }
}
.navigation a.btn-switch {
  display: flex;
  align-items: center;
  width: max-content;
}
.navigation a.btn-switch svg {
  width: 1em;
  height: 1em;
  margin-left: 0.25em;
  transform: rotate(-45deg);
}

.navigation.navigation--closed {
  max-height: 0;
  opacity: 0;
}
@media screen and (min-width: 61.875em) {
  .navigation.navigation--closed {
    max-height: none;
    opacity: 1;
  }
}

/* Navigation component (FSI-brand site)
****************************************/
.navigation a[aria-current] {
  color: var(--color_primary);
}

/* Main component manifest file 
 * (FSI-brand site)
*******************************/
/* Main component (default)
***************************/
.main {
  position: relative;
}
.main[tabindex] {
  outline: 0;
}

/* Main component (FSI-brand site)
**********************************/
/* Article component manifest file 
 * (FSI-brand site)
**********************************/
/* Article component (default)
******************************/
.main > .article.landmark {
  margin-bottom: 3.75em;
}
@media screen and (min-width: 48em) {
  .main > .article.landmark {
    margin-bottom: 4.5em;
  }
}

@media screen and (min-width: 61.875em) {
  @supports (display: grid) {
    .tmpl-report .main > .article.landmark {
      display: grid;
      grid-template-columns: minmax(auto, 300px) 1fr;
      column-gap: 3.75em;
      /* prettier-ignore */
      grid-template-areas: "hero hero" "rail content";
    }
  }
}
.tmpl-report .article.landmark > .hero-container.landmark {
  grid-area: hero;
}
.tmpl-report .article.landmark > .toc-container {
  grid-area: rail;
}
.tmpl-report .article.landmark > .container.report-content {
  grid-area: content;
}
.tmpl-report .article.landmark > .toc-container,
.tmpl-report .article.landmark > .container.report-content .section:first-child {
  margin-top: -1.5em;
}
@media screen and (min-width: 61.875em) {
  .tmpl-report .article.landmark > .toc-container,
.tmpl-report .article.landmark > .container.report-content .section:first-child {
    margin-top: 0;
  }
}

/* Article component (FSI-brand site)
*************************************/
/* Hero component manifest file 
 * (FSI-brand site)
*******************************/
/* Hero component (default)
***************************/
.hero-container {
  position: relative;
  margin-left: -1.5em;
  margin-right: -1.5em;
}
@media screen and (min-width: 80em) {
  .hero-container {
    margin-left: calc(-50vw + ((80em / 2) - 1em));
    margin-right: calc(-50vw + ((80em / 2) - 1em));
  }
}
.hero-container.landmark {
  margin-top: 0;
}

.hero {
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 100em;
  margin: 0 auto;
  color: var(--color_white);
  background-color: var(--color_primary);
  overflow: hidden;
}
@media screen and (min-width: 48em) {
  .hero {
    min-height: 480px;
  }
}
@media screen and (min-width: 64em) {
  .hero {
    flex-direction: row;
    border-radius: var(--border_radius);
  }
}

.hero-image {
  position: static;
  top: 0;
  left: 0;
  width: 100%;
  height: 280px;
}
@media screen and (min-width: 64em) {
  .hero-image {
    position: absolute;
    height: 100%;
  }
}
.hero-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  flex-grow: 1;
  position: relative;
  width: 100%;
  min-height: 30vh;
  padding: 2em;
  background-color: var(--color_primary);
}
@media screen and (min-width: 64em) {
  .hero-text {
    flex-grow: 0;
    width: 50%;
    min-height: 480px;
    padding-left: 0;
    padding-right: 0;
  }
  .tmpl-home .hero-text {
    min-height: 600px;
  }
}
.hero-text h1 {
  margin-bottom: 0.5263157895em;
  font-size: 2.375em;
  font-weight: 300;
  line-height: 1.1579;
}
@media screen and (min-width: 64em) {
  .hero-text .constrain-width {
    padding-left: 15%;
    padding-right: 15%;
  }
}

.hero-description {
  color: var(--color_hero--lead);
  font-size: 1.25rem;
  line-height: 1.3;
}
.hero-description p:first-child {
  margin-top: 0;
}
.hero-description a {
  color: inherit;
}

.hero-cta {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  color: var(--color_white);
}
.hero-cta .hero-link {
  display: flex;
  margin: 0.5em 1em 0.5em 0;
  color: inherit;
  font-weight: 400;
}
.hero-cta .btn + .hero-link, .hero-cta .toc-btn-headings a + .hero-link, .toc-btn-headings .hero-cta a + .hero-link {
  margin-left: 1em;
}
.hero-cta svg {
  margin-right: 0.375em;
  stroke: inherit;
}

/* Hero component (FSI-brand site)
**********************************/
.hero-cta {
  color: #fff;
}

/* Columns component manifest file 
 * (FSI-brand site)
**********************************/
/* Columns component (default)
******************************/
.columns {
  --columns_number: 2;
  --columns_gap: 1em;
  grid-template-columns: repeat(auto-fit, minmax(316px, 1fr));
}
@media screen and (min-width: 64em) {
  .columns {
    grid-template-columns: repeat(var(--columns_number), 1fr);
  }
}
@supports (display: grid) {
  .columns {
    display: grid;
  }
}

.columns:not(.columns--dividers) {
  gap: 1em;
}
@media screen and (min-width: 64em) {
  .columns:not(.columns--dividers) {
    gap: var(--columns_gap);
  }
}

.columns.columns.columns--3,
.panel .columns.columns--dividers {
  grid-template-columns: auto;
}
@media screen and (min-width: 61.875em) {
  .columns.columns.columns--3,
.panel .columns.columns--dividers {
    grid-template-columns: repeat(var(--columns_number), 1fr);
  }
}

.columns.columns--dividers {
  overflow: hidden;
}
.columns.columns--dividers > *.column-item {
  margin: 0;
  padding: 2.25em 1.5em;
  border-radius: 0;
}
@media screen and (min-width: 48em) {
  .columns.columns--dividers > *.column-item {
    padding-left: 2.25em;
    padding-right: 2.25em;
  }
}

.columns-container.vertical-flow {
  margin-top: 0px !important;
}

.title + .columns-container.vertical-flow, .text + .columns-container.vertical-flow {
  margin-top: 0;
}

/* Columns component (FSI-brand site)
*************************************/
.scheme--light-gray .columns.columns--dividers > *.column-item {
  box-shadow: 0 0 0 0.03125em #bac1ca;
}
.scheme--primary .columns.columns--dividers > *.column-item {
  box-shadow: 0 0 0 0.03125em #7fe3c85c;
}
.scheme--secondary .columns.columns--dividers > *.column-item {
  box-shadow: 0 0 0 0.03125em #2f6c7f;
}
.scheme--white .columns.columns--dividers > *.column-item {
  box-shadow: 0 0 0 0.03125em #c5cad1;
}

/* Section component manifest file 
 * (FSI-brand site)
**********************************/
/* Section component (default)
******************************/
.section.landmark {
  clear: both;
}

/* Section component (FSI-brand site)
*************************************/
/* OneTrust Consent Banner component manifest file
***********************************/
/* OneTrust Consent Banner Component (default)
*******************************/
#onetrust-banner-sdk.otCenterRounded {
  display: none !important;
  width: 80vw !important;
  max-width: max-content !important;
  left: 0em !important;
}
@media only screen and (max-width: 425px) {
  #onetrust-banner-sdk.otCenterRounded {
    width: 95vw !important;
  }
}

#onetrust-banner-sdk.ot-bnr-w-logo .ot-bnr-logo {
  height: 40px !important;
  width: auto !important;
}

#onetrust-banner-sdk #onetrust-policy-text,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-desc {
  color: #475966 !important;
  font-size: 16px !important;
  line-height: 25.6px !important;
}

#onetrust-banner-sdk #onetrust-policy-title,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-title {
  font-size: 24px !important;
}

#onetrust-banner-sdk #onetrust-reject-all-handler,
#onetrust-pc-sdk .ot-pc-refuse-all-handler,
#onetrust-banner-sdk #onetrust-pc-btn-handler {
  color: #002a4b !important;
  background-color: #ffffff !important;
  border-color: #002a4b !important;
  font-weight: 500 !important;
  border-radius: 2.5em !important;
}

#onetrust-banner-sdk #onetrust-accept-btn-handler,
#onetrust-banner-sdk #onetrust-reject-all-handler,
#onetrust-banner-sdk #onetrust-pc-btn-handler,
#onetrust-pc-sdk #accept-recommended-btn-handler {
  font-size: 16px !important;
  font-weight: 500 !important;
  border-radius: 2.5em !important;
}

#onetrust-banner-sdk #onetrust-reject-all-handler:hover,
#onetrust-banner-sdk #onetrust-pc-btn-handler:hover,
#onetrust-pc-sdk .ot-pc-refuse-all-handler:hover {
  color: #ffffff !important;
  background-color: #3699c9 !important;
  border-color: #3699c9 !important;
}

#onetrust-pc-sdk .save-preference-btn-handler {
  border-radius: 2.5em !important;
}

@media only screen and (max-width: 768px) {
  #onetrust-banner-sdk .banner-actions-container {
    display: flex !important;
    flex-wrap: wrap !important;
  }

  #onetrust-banner-sdk #onetrust-reject-all-handler {
    order: 2 !important;
  }
}
#onetrust-pc-sdk .ot-cat-grp .ot-always-active {
  color: #002a4b !important;
}

#onetrust-pc-sdk .ot-tgl input:checked + .ot-switch .ot-switch-nob {
  border: 1px solid #002a4b !important;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
#onetrust-pc-sdk .ot-tgl input:checked + .ot-switch .ot-switch-nob::before {
  height: 21px;
  width: 21px;
  background-color: #002a4b !important;
  border-color: #002a4b !important;
}

#onetrust-pc-sdk .ot-pc-header {
  padding: 10px 0 10px 10px !important;
}

#onetrust-pc-sdk .ot-pc-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 0px !important;
}

#onetrust-banner-sdk a:hover,
#onetrust-pc-sdk a:hover {
  color: #cc181f !important;
  text-decoration: unset !important;
}

.onetrust-banner-sdk-open {
  overflow: hidden;
}

a.ot-opt-out-notice-link {
  color: #002a4b !important;
}

button#ot-sdk-btn.ot-sdk-show-settings {
  background-color: unset !important;
  color: #ffffff !important;
  font-size: 1rem !important;
  line-height: 1.6em !important;
  letter-spacing: 0.32px !important;
  border: none !important;
  padding: 0 !important;
}

button#ot-sdk-btn.ot-sdk-show-settings:hover {
  color: #ffffff !important;
  text-decoration: underline;
}

#onetrust-pc-sdk .ot-pc-footer-logo,
.hide-one-trust-banner #onetrust-banner-sdk.otCenterRounded {
  display: none !important;
}

.hide-one-trust-banner .onetrust-pc-dark-filter {
  position: unset !important;
}

/* Image component manifest file 
 * (FSI-brand site)
********************************/
/* Image component (default)
****************************/
.image {
  break-inside: avoid;
  overflow: hidden;
}
.image figure,
.image img {
  width: 100%;
  margin: 0 auto;
}
.image img.width-auto {
  width: auto;
}
.image > img {
  background-color: #fff;
  border-radius: var(--border_radius);
}
.image figure {
  display: table;
  background-color: #fff;
}
.image figcaption {
  display: table-caption;
  caption-side: bottom;
  background-color: #fff;
}
@media screen and (min-width: 38.75em) {
  .image.float--left, .image.float--right {
    margin-top: 0;
  }
  .image.float--left {
    margin-right: 2em;
  }
  .image.float--right {
    margin-left: 2em;
  }
}

.image:not(.float--left):not(.float--right) + .title, .image:not(.float--left):not(.float--right) + .text {
  margin-top: 2em;
}
@media screen and (min-width: 48em) {
  .image:not(.float--left):not(.float--right) + .title, .image:not(.float--left):not(.float--right) + .text {
    margin-top: 3em;
  }
}

/* Image component (FSI-brand site)
***********************************/
/* List component manifest file 
 * (FSI-brand site)
*******************************/
/* List component (default)
***************************/
.list {
  margin: 1em 0;
  padding: 0 1em;
}
/* List component (FSI-brand site)
**********************************/
/* Text component manifest file 
 * (FSI-brand site)
*******************************/
/* Text component (default)
***************************/
.text blockquote {
  font-size: 1.125rem;
  font-style: italic;
  max-width: 75ch;
  margin-left: auto;
  margin-right: auto;
  padding: 0 2em;
  color: inherit;
  text-align: center;
}
.text blockquote a {
  color: inherit;
}
.text hr {
  margin: 2.5em 0;
  color: var(--color_heading_border);
}
.text p,
.text li {
  letter-spacing: 0.03125em;
}
.text p:first-child {
  margin-top: 0;
}
.text sub,
.text sup {
  font-style: italic;
}
.text > ul,
.text > ol {
  margin: 1em 0;
  padding-left: 1.25em;
}
.text .underline {
  display: block;
}
.text th, .text td, .text #topCell {
  padding: 3px 7px;
  text-align: right;
  font-size: 0.95rem;
  font-weight: 400;
}
.text th {
  background-color: #40959d;
  color: white;
}
.text td {
  border-bottom: solid 0.5px #40959d;
}
.text #topCell {
  background-color: #00727d;
  color: white;
}
.text #blankCell {
  background-color: white;
  color: #59667C;
  border-bottom: 0;
}
.text table {
  margin-bottom: 25px;
}
@media (min-width: 700px) and (max-width: 1000px) {
  .text .cmp-text table {
    width: 100%;
  }
}
@media (min-width: 0) and (max-width: 700px) {
  .text .cmp-text table {
    display: block;
    overflow-x: auto;
    width: 100%;
  }
}

/* Text component (FSI-brand site)
**********************************/
/* Text component (Columns variant)
***********************************/
.columns:not(.columns--dividers) > .text + .text {
  margin-top: -1em;
}
@media screen and (min-width: 43em) {
  .columns:not(.columns--dividers) > .text + .text {
    margin-top: initial;
  }
}

/* Text component (Panel variant)
*********************************/
.panel .text a {
  text-decoration: underline;
}
.panel .text a:hover, .panel .text a:focus {
  text-decoration: none;
}

.scheme--primary .text h1,
.scheme--primary .text h2,
.scheme--primary .text h3,
.scheme--primary .text h4,
.scheme--primary .text a,
.scheme--secondary .text h1,
.scheme--secondary .text h2,
.scheme--secondary .text h3,
.scheme--secondary .text h4,
.scheme--secondary .text a {
  color: inherit;
}
.scheme--primary .text .underline,
.scheme--secondary .text .underline {
  border-color: inherit;
}

/* Text component manifest file 
 * (FSI-brand site)
*******************************/
/* Title component (default)
****************************/
.title-lead p:first-child {
  margin-top: 0;
}

/* Title component (FSI-brand site)
***********************************/
/* Title component (Panel variant)
**********************************/
.panel .title {
  max-width: 42.5em;
  margin: 0 auto;
  text-align: center;
}
.panel .title-lead {
  max-width: 27.7272727273em;
  margin: 0 auto;
}

.scheme--primary .title .heading,
.scheme--primary .title .heading.outline,
.scheme--secondary .title .heading,
.scheme--secondary .title .heading.outline {
  color: inherit;
  border-color: inherit;
}

/* Aside/Pull Quote component manifest file 
 * (FSI-brand site)
*******************************************/
/* Aside/Pull Quote component (default)
***************************************/
@media screen and (min-width: 38.75em) {
  .aside-container.float--left, .aside-container.float--right {
    max-width: 20em;
    margin: 0.375em 0 1.25em;
  }
}
@media screen and (min-width: 38.75em) {
  .aside-container.float--left {
    margin-right: 1.875em;
  }
}
@media screen and (min-width: 38.75em) {
  .aside-container.float--right {
    margin-left: 1.875em;
  }
}

.aside {
  font-size: 1.125rem;
  margin: 0;
  padding: 2em;
  background-color: #fff;
  border-left: solid 0.2777777778em var(--color_tertiary);
}

/* Aside/Pull Quote component (FSI-brand site)
**********************************************/
/* Board component manifest file 
 * (FSI-brand site)
********************************/
/* Board component (default)
****************************/
.board {
  position: relative;
  letter-spacing: 0.03125em;
  break-inside: avoid;
  border-radius: var(--border_radius);
  overflow: hidden;
}
@media screen and (min-width: 48em) {
  .board-image {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}
.board-text {
  position: relative;
  padding: 2.25em;
  color: var(--color_scheme_text);
  background-color: #fff;
  background-color: var(--color_scheme_background);
}
@media screen and (min-width: 48em) {
  .board-text {
    max-width: 50%;
    margin-left: 50%;
  }
}
@media screen and (min-width: 48em) {
  .board-container:nth-of-type(2n) .board-text {
    margin-left: 0;
  }
}

.board-actions {
  padding: 0;
  list-style: none;
}
.board-actions li + li {
  margin-top: 0.75em;
}
.board-actions li a {
  display: inline-flex;
  font-weight: 400;
}
.board-actions svg {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  margin-left: 0.25em;
  flex-shrink: 0;
}

.board-cta {
  margin: 0;
}
@media screen and (min-width: 30em) {
  .board-cta {
    text-align: right;
  }
}

/* Board component (FSI-brand site)
***********************************/
/* Card component manifest file 
 * (FSI-brand site)
*******************************/
/* Card component (default)
***************************/
.card {
  --color_scheme_background: var(--color_white);
  --color_scheme_text: var(--color_text);
  display: flex;
  flex-direction: column;
  break-inside: avoid;
  border-radius: var(--border_radius);
  overflow: hidden;
}

.card-inset {
  flex: 1 0 auto;
  padding: 2.25em;
  color: var(--color_scheme_text);
  background-color: var(--color_scheme_background);
}

.card-inset,
.card-text {
  display: flex;
  flex-direction: column;
}

.card-text,
.card-description {
  flex-grow: 1;
}

.card-icon {
  flex-shrink: 0;
  margin-bottom: 0.75em;
}

.card-description *:first-child {
  margin-top: 0;
}
.card-cta {
  margin: 0;
}

@media screen and (min-width: 30em) {
  .card-inset.card--horizontal {
    flex-direction: row;
  }
}
.card-inset.card--horizontal .card-icon {
  margin-right: 1.5em;
}

.scheme--primary .card-image + .card-text,
.scheme--secondary .card-image + .card-text {
  color: #fff;
}
@supports (color: var(--color_scheme_text)) {
  .scheme--primary .card-image + .card-text,
.scheme--secondary .card-image + .card-text {
    color: var(--color_scheme_text);
  }
}

/* Card component (FSI-brand site)
**********************************/
.card-inset.scheme--primary .icon,
.card-inset.scheme--secondary .icon {
  stroke: var(--color_tertiary);
}

/* Card component (variants by context)
***************************************/
.columns.columns--dividers .card-inset {
  padding: 0;
}

/* Card Hero component manifest file 
 * (FSI-brand site)
************************************/
/* Card Hero component (default)
********************************/
.card-hero {
  --color_scheme_background: var(--color_secondary);
  --color_scheme_text: var(--color_white);
}
.card-hero .card-inset {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  min-height: 450px;
  padding: 0;
  color: var(--color_scheme_text);
  background-color: var(--color_scheme_background);
}
.card-hero .card-image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.card-hero .card-text,
.card-hero .card-description {
  flex-grow: 0;
}
.card-hero .card-text {
  position: relative;
  max-width: 38.75em;
  padding: 1.5em;
}
.card-hero .card-text--outline {
  /* prettier-ignore */
  text-shadow: -1px -1px 0 #555, 1px -1px 0 #555, -1px 1px 0 #555, 1px 1px 0 #555;
}
.card-hero .card-description p {
  font-size: 2rem;
  font-weight: 400;
}
.card-hero .card-description a {
  text-decoration: underline;
}
.card-hero .card-description a:hover, .card-hero .card-description a:focus {
  text-decoration: none;
}

.card-hero .scheme--light-gray .card-text--outline,
.card-hero .scheme--white .card-text--outline {
  /* prettier-ignore */
  text-shadow: -1px -1px 0 #eee, 1px -1px 0 #eee, -1px 1px 0 #eee, 1px 1px 0 #eee;
}

/* Card Hero component (FSI-brand site)
***************************************/
/* Card Profile component manifest file 
 * (FSI-brand site)
***************************************/
/* Card Profile component (default)
***********************************/
.card-profile .card-inset {
  align-items: center;
}
.card-profile .card-image {
  margin-bottom: 1.5em;
  overflow: hidden;
}
.card-profile .card-image img {
  width: 10.75em;
  height: 10.75em;
  border-radius: 50%;
  object-fit: cover;
}
.card-profile .card-text {
  max-width: 60ch;
}
.card-profile .card-title,
.card-profile .card-credential {
  text-align: center;
}
.card-profile .card-credential {
  margin: 0 0 1em;
  font-weight: 400;
  letter-spacing: 0.0625em;
}
.card-profile .card-description {
  padding-top: 0.75em;
  border-top: solid 0.0625em #eef1f4;
}
.card-profile .card-cta {
  padding-top: 1em;
}

/* Card Profile component (FSI-brand site)
******************************************/
.card-profile .card-credential {
  color: #666;
}

/* CTA component manifest file 
 * (FSI-brand site)
******************************/
/* CTA component (default)
**************************/
p.cta {
  display: flex;
  flex-wrap: wrap;
  margin: 0.625em -0.375em;
}
p.cta a {
  margin: 0.375em;
}

/* CTA component (FSI-brand site)
*********************************/
/* iFrame component manifest file 
 * (FSI-brand site)
*********************************/
/* iFrame component (default)
*****************************/
.iframe-container iframe {
  margin: 0 auto;
  border: none;
}

.iframe-responsive {
  position: relative;
  max-width: 100%;
}
.iframe-responsive.landscape-16x9 {
  padding-top: 56.25%;
}
.iframe-responsive.landscape-4x3 {
  padding-top: 75%;
}
.iframe-responsive iframe {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
}

/* iFrame component (FSI-brand site)
************************************/
/* Infogram component manifest file 
 * (FSI-brand site)
***********************************/
/* Infogram component (default)
*******************************/
.infogram {
  margin-left: 0;
  margin-right: 0;
  background-color: #fff;
}

/* Infogram component (FSI-brand site)
**************************************/
/* Panel component manifest file 
 * (FSI-brand site)
********************************/
/* Panel component (default)
****************************/
.panel-container {
  --color_scheme_text: var(--color_text);
  --color_scheme_background: var(--color_gray--light);
}
.panel-container + .panel-container {
  margin-top: -2.5em;
}

.panel {
  padding: 3.75em 1.5em;
  color: var(--color_scheme_text);
  background-color: var(--color_scheme_background);
  border-radius: var(--border_radius);
  overflow: hidden;
}
@media screen and (min-width: 48em) {
  .panel {
    padding-top: 4.5em;
    padding-bottom: 4.5em;
  }
}
.panel.panel--thin {
  padding-top: 2.25em;
  padding-bottom: 2.25em;
}

*:not(.columns) .panel.scheme--light-gray {
  padding-left: 0;
  padding-right: 0;
}

/* Panel component (FSI-brand site)
***********************************/
/* Various components (Panel variant)
*************************************/
.panel .panel-caveat {
  background-color: inherit;
}
.panel .panel-caveat figcaption {
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
}
.panel .panel-caveat figcaption p:last-child {
  margin-bottom: 0;
}
.panel > .panel-caveat {
  margin: -2.25em 0;
}
@media screen and (min-width: 48em) {
  .panel > .panel-caveat {
    margin-top: -3em;
    margin-bottom: -3em;
  }
}

/* Table component manifest file 
 * (FSI-brand site)
********************************/
/* Table component (default)
****************************/
.table {
  padding: 2.25em;
  background-color: var(--color_white);
  border-radius: var(--border_radius);
  overflow: hidden;
}
.table h2 {
  margin-bottom: 0.1666666667em;
}
.table p {
  margin-top: 0;
}

table,
.row-group {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

.row-group {
  margin: 1.5em 0;
}
.row-group dl {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
}
.row-group dt,
.row-group dd {
  flex: 0 0 calc(50% - 2px);
  margin: 0 1px;
}

.row-heading {
  margin-bottom: 0;
}

caption {
  text-align: initial;
}

th,
.row-heading,
.row-group dt {
  color: var(--color_secondary);
  font-weight: 400;
}

th,
td,
.row-heading,
.row-group dt,
.row-group dd {
  padding: 0.5em 1em;
  text-align: left;
  line-height: 1.5;
}

tbody,
.row-group {
  font-size: 0.875rem;
}

thead th:first-child,
tbody th,
tfoot th,
.row-heading,
.row-group dt {
  padding-left: 0;
}

thead th:last-child,
td:last-child,
.row-heading,
.row-group dd {
  padding-right: 0;
}

tr:not(:last-child) td,
tr:not(:last-child) th,
.row-group dt,
.row-group dd {
  border-bottom: dashed 0.0625em #a3b8cc;
}

thead th,
thead td,
.row-heading {
  border-bottom: solid 0.0625em var(--color_secondary);
}

tfoot td,
tfoot th {
  border-top: solid 0.0625em var(--color_secondary);
}

.table-overflow {
  margin: 0;
}
.table-overflow[tabindex="0"] {
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
}
.table-overflow[tabindex="0"] caption {
  max-width: 98vw;
}
.table-overflow[tabindex="0"] .scroll-notification {
  margin: 0;
}

.table--bestfit .tabular-data {
  display: none;
}
@media screen and (min-width: 48em) {
  .table--bestfit .tabular-data {
    display: block;
  }
}
@media screen and (min-width: 48em) {
  .table--bestfit .list-data {
    display: none;
  }
}

/* Table component (FSI-brand site)
***********************************/
/* Tabs component manifest file 
 * (FSI-brand site)
*******************************/
/* Tabs component (default)
***************************/
.tabs [role=tablist] {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  margin: 1.5em -0.375em;
  padding: 0;
  color: var(--color_primary);
  list-style: none;
}
@media screen and (min-width: 48em) {
  .tabs [role=tablist] {
    flex-direction: row;
  }
}
.tabs [role=tablist] > li {
  display: flex;
  flex-direction: column;
}
.tabs [role=tab] {
  margin: 0.1875em 0.375em;
  padding: 1.125em 1.25em;
  font-size: 1.125rem;
  border: solid 0.0625em #d0d4d9;
  border-radius: 0.625em;
}
@media screen and (min-width: 48em) {
  .tabs [role=tab] {
    margin-top: 0.375em;
    margin-bottom: 0.375em;
  }
}
.tabs [role=tab]:focus, .tabs [role=tab]:hover {
  border-color: currentColor;
}
.tabs [role=tab][aria-selected] {
  background-color: #fff;
}

.tab-panel:not([role=tabpanel]) {
  margin-top: 2em;
}

/* Tabs component (FSI-brand site)
**********************************/
.tabs [role=tab] {
  color: var(--color_secondary);
}

/* Teaser component manifest file 
 * (FSI-brand site)
*********************************/
/* Teaser component (default)
*****************************/
.teaser {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  margin: 1.5em 0;
  background-color: #fff;
  border-radius: var(--border_radius);
  break-inside: avoid;
  overflow: hidden;
}
.teaser p {
  max-width: 68ch;
}
.teaser:hover, .teaser:focus-within {
  box-shadow: 0.0625em 0.0625em 0.875em -0.125em rgba(102, 102, 102, 0.45);
}
.columns > .teaser {
  margin: 0;
}

.teaser-image {
  position: relative;
  min-height: 13.375em;
}
.teaser:not(.teaser-horizontal) .teaser-image {
  border-bottom: solid 0.25em var(--color_img_border);
}
.teaser-image img {
  min-height: 13.375em;
}
.teaser-image .flag-preview {
  font-size: 0.875rem;
  position: absolute;
  top: 0;
  left: -3.4285714286em;
  min-width: 284px;
  margin: 3.4285714286em 0 0;
  padding: 0.2142857143em 3.4285714286em;
  color: #fff;
  background-color: var(--color_primary);
  font-weight: 400;
  letter-spacing: 0.0357142857em;
  text-align: center;
  text-transform: uppercase;
  transform: rotate(-34deg);
}
.teaser:hover .teaser-image, .teaser:focus-within .teaser-image {
  cursor: pointer;
}

.teaser-link {
  color: initial;
  text-decoration: none;
}
.teaser-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}
.teaser-link:focus {
  text-decoration: underline;
}
.teaser:focus-within .teaser-link:focus {
  text-decoration: none;
}

.teaser-text {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  padding: 1.5em 2.25em 2.25em;
  background-color: var(--color_white);
  z-index: 1;
}

.teaser-title a {
  color: inherit;
}

.teaser-description {
  background-color: var(--color_white);
}
.teaser-description > p:first-child {
  margin-top: 0;
}
.teaser-description a {
  position: relative;
  z-index: 1;
}

.teaser-cta {
  margin: auto 0 0;
}
.teaser:hover .teaser-cta, .teaser:focus-within .teaser-cta {
  text-decoration: underline;
}

/* Teaser component (FSI-brand site)
************************************/
.teaser:hover .teaser-cta, .teaser:focus-within .teaser-cta {
  color: var(--color_primary);
}

/* Teaser Horizontal component manifest file 
 * (FSI-brand site)
********************************************/
/* Teaser Horizontal component (default)
****************************************/
@media screen and (min-width: 48em) {
  .teaser-horizontal {
    flex-direction: row;
    align-items: center;
  }
}
.teaser-horizontal .teaser-description > p:last-child {
  margin-bottom: 0;
}
@media screen and (min-width: 48em) {
  .teaser-horizontal .teaser-image {
    width: 33%;
    min-height: 11.5625em;
    align-self: stretch;
  }
  @supports (object-fit: cover) {
    .teaser-horizontal .teaser-image img {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .teaser-horizontal .teaser-text {
    padding-top: 2.25em;
    width: 66%;
  }
  .teaser-horizontal .teaser-description {
    padding-right: 3.75em;
  }
}

.teaser.teaser-horizontal .teaser-cta {
  justify-content: flex-end;
}
@media screen and (min-width: 48em) {
  .teaser.teaser-horizontal .teaser-cta {
    position: absolute;
    right: 2.25em;
    bottom: 2.25em;
    transition: right 400ms ease;
  }
}
.teaser.teaser-horizontal .teaser-cta svg {
  width: 1.4em;
  height: 1.4em;
  padding: 0.125em;
  stroke: var(--color_tertiary);
  stroke-width: 3;
  border: solid 0.125em var(--color_tertiary);
  border-radius: 50%;
}
@media screen and (min-width: 48em) {
  .teaser.teaser-horizontal:hover .teaser-cta, .teaser.teaser-horizontal:focus-within .teaser-cta {
    right: 1.5em;
  }
}

/* Teaser Horizontal component (FSI-brand site)
***********************************************/
/* TOC component manifest file 
 * (FSI-brand site)
******************************/
/* TOC component (default)
**************************/
.toc-container {
  position: relative;
}
.toc-container.vertical-flow {
  margin-top: -2.5em;
}
@media screen and (min-width: 61.875em) {
  .toc-container.vertical-flow {
    margin-top: inherit;
  }
}

.toc {
  color: #404959;
  background-color: white;
  border: solid 0.0625em var(--color_heading_border);
  border-radius: var(--border_radius);
  overflow: hidden;
}
.toc .toc-list li:last-child a {
  border-bottom: 0;
}

.toc-title.heading--tertiary {
  margin-bottom: 0;
}
@media screen and (min-width: 61.875em) {
  .toc-title.heading--tertiary {
    display: none;
  }
}

.toc-button {
  display: block;
  width: 100%;
  padding: 0.625em 1em;
  font-weight: 400;
  background-color: #eef1f4;
}

.toc-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.toc-list a {
  position: relative;
  display: block;
  color: inherit;
  text-decoration: none;
  overflow-wrap: anywhere;
}
.toc-list a::after {
  position: absolute;
  top: calc(50% - 4px);
  right: 30px;
  display: block;
  width: 8px;
  height: 8px;
  background-color: transparent;
  border-radius: 50%;
  opacity: 0;
  transition: all 0.3s ease;
  content: "";
}
.toc-list a:hover, .toc-list a:focus {
  text-decoration: underline;
}
.toc-list a.highlight::after {
  right: 10px;
  opacity: 1;
}

.toc-headings {
  --border_color: #e3e6e5;
}
.toc-headings a:not(.section-heading) {
  font-size: 0.9375rem;
  letter-spacing: 0.02em;
  line-height: 1.2667;
  padding: 0.4em 1.3333333333em;
  border-bottom: dashed 0.0666666667em var(--border_color);
}
.toc-headings a.section-heading {
  margin-top: -1px;
  padding: 0.625em 1em;
  font-weight: 400;
  background-color: #eef1f4;
  border-top: solid 0.0625em var(--border_color);
}

.toc-downloads {
  padding: 0.375em;
  background-color: var(--color_secondary);
}
.toc-downloads a {
  display: flex;
  align-items: center;
  padding: 0.375em 1.25em;
  color: #fff;
  font-weight: 400;
}
.toc-downloads svg {
  margin-right: 0.75em;
}

@media screen and (min-width: 61.875em) {
  .tmpl-report .toc.sticky {
    position: sticky;
    top: 2.5em;
  }
}

/* TOC component (FSI-brand site)
*********************************/
.toc-list a.highlight::after {
  background-color: var(--color_tertiary);
}

.toc-downloads a {
  color: #7fe3c8;
}

/* TOC Buttons component manifest file 
 * (FSI-brand site)
**************************************/
/* TOC Buttons component (default)
**********************************/
.toc-btn-headings {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: -0.1875em;
}
@media screen and (min-width: 23em) {
  .toc-btn-headings {
    flex-direction: row;
  }
}
.toc-btn-headings a {
  margin: 0.1875em;
  padding: 0.375em 1.25em;
  color: var(--color_btn_text);
  font-weight: 300;
  letter-spacing: 0.03125em;
}
.toc-btn-headings a:hover, .toc-btn-headings a:focus {
  text-decoration: none;
}

/* TOC Buttons component (FSI-brand site)
*****************************************/
.toc-btn-headings a {
  --color_btn_background: #b3dcd9;
  --color_btn_border: var(--color_secondary);
  --color_btn_text: var(--color_secondary);
}
.toc-btn-headings a:hover, .toc-btn-headings a:focus {
  --color_btn_background: var(--color_primary);
  --color_btn_text: var(--color_white);
}

/* Video component manifest file 
 * (FSI-brand site)
********************************/
/* Video component (default)
****************************/
.video {
  margin: 0;
  background-color: #fff;
}

.video-responsive {
  position: relative;
  max-width: 100%;
}
.js .video-responsive > .ratio-16x9 {
  padding-top: 56.25%;
}
.js .video-responsive > .ratio-4x3 {
  padding-top: 75%;
}
.video-responsive iframe {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

/* Video component (FSI-brand site)
***********************************/
/* Back-to-top component manifest file 
 * (FSI-brand site)
**************************************/
/* Back-to-top component (default)
**********************************/
.back-top {
  display: flex;
  justify-content: flex-end;
  position: sticky;
  bottom: 0;
  margin: 0;
  z-index: 1;
}
.back-top a {
  display: block;
  padding: 0.875em;
  color: var(--color_white);
  background-color: var(--color_primary);
  border-top-left-radius: var(--border_radius);
  border-top-right-radius: var(--border_radius);
}
.back-top svg {
  width: 1.4em;
  height: 1.4em;
  padding: 0.125em;
  stroke-width: 3;
  transform: rotate(-90deg);
  border: solid 0.125em transparent;
  border-radius: 50%;
}

/* Back-to-top component (FSI-brand site)
******************************************/
.back-top svg {
  stroke: var(--color_tertiary);
  border-color: var(--color_tertiary);
}

/* Footer component manifest file 
 * (FSI-brand site)
*********************************/
/* Footer component (default)
*****************************/
.footer {
  padding-top: 3em;
  padding-bottom: 2em;
  color: var(--color_footer_text);
  background-color: var(--color_footer_background);
  border-top: solid 0.125em var(--color_footer_border);
}
.footer .list {
  padding: 0;
  list-style: none;
}
.footer .list a,
.footer .text a {
  color: inherit;
  font-weight: inherit;
}

.footer-layout {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1em;
}
@media screen and (min-width: 62.25em) {
  .footer-layout {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* Footer component (FSI-brand site)
************************************/
/* Footer/disclaimer component manifest file 
 * (FSI-brand site)
********************************************/
/* Footer/disclaimer component (default)
****************************************/
.footer-end {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 1.5em;
  padding-top: 0.625em;
  border-top: solid 0.0625em currentColor;
}
@media screen and (min-width: 38.75em) {
  .footer-end {
    flex-direction: row;
    align-items: center;
  }
}
.footer-end a {
  color: inherit;
}

.footer-disclaimer {
  grid-column: -1/1;
  font-size: 0.875rem;
}

/* Footer/disclaimer component (FSI-brand site)
***********************************************/
.footer-end {
  color: var(--color_hero--lead);
  border-color: rgba(255, 255, 255, 0.2);
}

/* Footer/logos component manifest file 
 * (FSI-brand site)
****************************************/
/* Footer/logos component (default)
***********************************/
.footer-logos {
  display: flex;
  margin: 0 -1em;
  padding: 0;
  flex-wrap: wrap;
  grid-column: -1/1;
}
@media screen and (min-width: 62.25em) {
  .footer-logos {
    flex-wrap: nowrap;
    grid-column: auto;
    justify-self: right;
  }
}
.footer-logos svg {
  width: 150px;
  height: 39px;
}
.footer-logos .brand-logo {
  margin: 0.5em 1em;
}
.footer-logos .logo-fsi .logo-symbol {
  fill: #60bdb3;
}
.footer-logos .logo-mufg .logo-symbol {
  fill: #e60000;
}

/* Footer/logos component (FSI-brand site)
******************************************/
.footer-logos .logo-text {
  fill: var(--color_footer_text);
}