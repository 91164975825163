/* Teaser component (FSI-brand site)
************************************/

.teaser-cta {
    // The teaser hover/focus & focus-within states
    .teaser:hover &,
    .teaser:focus-within & {
        color: var(--color_primary);
    }
}
