/* Icon styles (default)
************************/

.icon {
    width: em(24px, 16px);
    height: em(24px, 16px);
    fill: none;

    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2;
}

.icon-roundal {
    width: 60px;
    height: 60px;

    stroke-miterlimit: 10;

    // &.icon--avatar {}
    // &.icon--leaf {}

    // &.icon--tractor {
    //     stroke-linecap: square;
    // }
}

.icon--menu {
    width: 1em;
    height: 1em;
}

.icon--pdf,
.icon--speech {
    fill: currentColor;
    stroke: none;
}
