/* iFrame component (default)
*****************************/

.iframe-container {
    iframe {
        margin: 0 auto;
        // background-color: nnn;
        // ^^ Clearly shows letter-boxing when using fixed width/height settings;
        // Useful reminder to content editors to prefer responsive settings dropdown values
        border: none;
    }
}

.iframe-responsive {
    position: relative;
    max-width: 100%;

    &.landscape-16x9 {
        padding-top: 56.25%;
    }

    &.landscape-4x3 {
        padding-top: 75%;
    }

    iframe {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        margin: 0;
    }
}
