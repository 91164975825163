/* Image component (default)
****************************/

.image {
    break-inside: avoid;
    overflow: hidden;

    figure,
    img {
        width: 100%;
        margin: 0 auto;
    }

    img.width-auto {
        width: auto;
    }

    > img {
        background-color: $white;
        border-radius: var(--border_radius);
    }

    figure {
        display: table;
        background-color: $white;
    }

    figcaption {
        display: table-caption;
        caption-side: bottom;
        background-color: $white;
    }

    @media screen and (min-width: $viewport-narrow) {
        &.float--left,
        &.float--right {
            margin-top: 0;
        }

        &.float--left {
            margin-right: 2em;
        }

        &.float--right {
            margin-left: 2em;
        }
    }
}

.image:not(.float--left):not(.float--right) {
    & + .title,
    & + .text {
        margin-top: em(32px, 16px);

        @media screen and (min-width: $viewport-medium) {
            margin-top: em(48px, 16px);
        }
    }
}
